import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import variables from '_config/css-variables'
import { Image, Container, Text, Heading } from '_atoms'

import style from './styles.module.css'

const BluebenxVision = ({
  visionTitle,
  visionDescription,
  visionImage,
  visionImageAlt,
  visionBackground,
}) => {
  return (
    <div className={style.vision} style={{ backgroundImage: `url(${visionBackground})` }}>
      <Container className={style.visionContainer}>
        <div className={style.visionInfo}>
          <div className={style.visionContent}>
            <div className={style.visionDescriptionWrapper}>
              <Fade delay={200} distance="10%" bottom>
                <Heading type="h2" className={style.visionTitle}>
                  {visionTitle}
                  <span className={style.dotSecondary}>.</span>
                </Heading>
                <Text
                  color={variables['color-white']}
                  size="22"
                  splitParagraphs
                  className={style.visionDescription}
                >
                  {visionDescription}
                </Text>
              </Fade>
            </div>
          </div>
          <div className={style.visionImage}>
            <Fade delay={450} distance="20%" bottom>
              <Image src={visionImage} className={style.visionImg} alt={visionImageAlt} />
            </Fade>
          </div>
        </div>
      </Container>
    </div>
  )
}

BluebenxVision.propTypes = {
  visionTitle: PropTypes.string.isRequired,
  visionDescription: PropTypes.string.isRequired,
  visionImage: PropTypes.string.isRequired,
  visionImageAlt: PropTypes.string.isRequired,
  visionBackground: PropTypes.string.isRequired,
}

export default BluebenxVision
