import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'

import { Container, Heading, Text } from '_atoms'

import styles from './styles.module.css'

const SwfastSolutionContainer = ({ title, description, image, imageAlt }) => {
  return (
    <>
      <div className={styles.solutionImage}>
        <Fade delay={450} distance="20%" bottom>
          <Img
            fluid={image}
            alt={imageAlt}
            imgStyle={{
              objectFit: 'cover',
              position: 'relative',
            }}
            fadeIn={false}
          />
        </Fade>
      </div>
      <Container className={styles.solutionContainer}>
        <div className={styles.solutionDescription}>
          <Fade distance="10%" bottom>
            <Heading type="h4" bold className={styles.heading}>
              {title}
              <span className={styles.dotSecondary}>.</span>
            </Heading>
          </Fade>

          <div className={styles.text}>
            <Fade delay={200} distance="10%" bottom>
              <Text color="dark" size="32" splitParagraphs className={styles.paragraph}>
                {description}
              </Text>
            </Fade>
          </div>
        </div>
      </Container>
    </>
  )
}

SwfastSolutionContainer.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  imageAlt: PropTypes.string.isRequired,
}

export default SwfastSolutionContainer
