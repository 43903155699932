/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable react/destructuring-assignment */
import React from 'react'

const styles = {
  countdownContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    margin: 'auto',
  },
  svg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transform: 'rotateY(-180deg) rotateZ(-90deg)',
    overflow: 'visible',
  },
  button: {
    fontSize: 16,
    padding: '15px 40px',
    margin: '10px auto 30px',
    display: 'block',
    backgroundColor: '#4d4d4d',
    color: 'lightgray',
    border: 'none',
    cursor: 'pointer',
    outline: 0,
  },
}

class CountdownTimer extends React.Component {
  milliseconds

  radius

  circumference

  strokeDashoffset

  constructor(props) {
    super(props)

    const { seconds, size } = this.props

    this.milliseconds = seconds * 1000
    this.radius = size / 2
    this.circumference = size * Math.PI

    this.state = {
      countdown: this.milliseconds,
      isPlaying: false,
    }

    this.strokeDashoffset = () =>
      this.circumference - (this.state.countdown / this.milliseconds) * this.circumference
  }

  componentDidMount() {
    this.startTimer()
  }

  startTimer = () => {
    this.setState({ isPlaying: true })

    const interval = setInterval(() => {
      this.setState({ countdown: this.state.countdown - 10 })

      if (this.state.countdown === 0) {
        clearInterval(interval)
        this.setState({
          countdown: this.milliseconds,
          isPlaying: false,
        })
      }
    }, 10)
  }

  render() {
    const { size, strokeColor, strokeBgColor, strokeWidth } = this.props
    const countdownSizeStyles = {
      height: size,
      width: size,
    }

    const textStyles = {
      color: strokeColor,
      fontSize: size * 0.3,
    }

    const seconds = (this.state.countdown / 1000).toFixed()

    return (
      <div>
        <div style={{ ...styles.countdownContainer, ...countdownSizeStyles }}>
          <p style={textStyles}>{seconds}s</p>
          <svg style={styles.svg}>
            <circle
              cx={this.radius}
              cy={this.radius}
              r={this.radius}
              fill="none"
              stroke={strokeBgColor}
              strokeWidth={strokeWidth}
            />
          </svg>
          <svg style={styles.svg}>
            <circle
              strokeDasharray={this.circumference}
              strokeDashoffset={this.state.isPlaying ? this.strokeDashoffset() : 0}
              r={this.radius}
              cx={this.radius}
              cy={this.radius}
              fill="none"
              strokeLinecap="round"
              stroke={strokeColor}
              strokeWidth={strokeWidth}
            />
          </svg>
        </div>
      </div>
    )
  }
}

export default CountdownTimer
