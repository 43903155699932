import React from 'react'

import styles from './styles.module.css'

const BackgroundStripes = () => {
  return (
    <>
      <div className={styles.challengeBackground} />
      <div className={styles.stripes} />
    </>
  )
}
export default BackgroundStripes
