import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'

import { Container, Image, Heading } from '_atoms'

import TextJSON from '../../../text-json'

import styles from './styles.module.css'

const DiscoverContainer = ({
  discoverTitleFirstPart,
  discoverDescription,
  discoverTitleLastChar,
  discoverImages,
}) => {
  return (
    <Container>
      <Row>
        <Col xs={12} sm={12} md={5} lg={5}>
          <div className={styles.discoverContent}>
            <Heading className={styles.discoverTitle} type="h4" color="white" bold>
              {discoverTitleFirstPart}
              <span className={styles.discoverTitleLastChar}>{discoverTitleLastChar}</span>
            </Heading>
            <TextJSON color="dark" size="22" className={styles.discoverParagraph}>
              {discoverDescription}
            </TextJSON>
          </div>

          <Col className={styles.discoverFixedMobile}>
            <Row>
              <Col span={10}>
                <Image
                  className={styles.discoverFixedMobileLeftArrowBaloon}
                  src={discoverImages.dialog1.image}
                  alt={discoverImages.dialog1.alt}
                />
                <Image
                  className={styles.discoverFixedMobileLeftNotArrow}
                  src={discoverImages.dialog2.image}
                  alt={discoverImages.dialog1.alt}
                />
              </Col>
              <Col span={2} />
            </Row>
            <Row>
              <Col span={2} />
              <Col span={10}>
                <Image src={discoverImages.dialog4.image} alt={discoverImages.dialog4.alt} />
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <Image
                  className={styles.discoverFixedMobileLeftArrowBaloon}
                  src={discoverImages.dialog5.image}
                  alt={discoverImages.dialog5.alt}
                />
              </Col>
              <Col span={2} />
            </Row>
            <Row>
              <Col span={2} />
              <Col span={10}>
                <Image src={discoverImages.dialog6.image} alt={discoverImages.dialog6.alt} />
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <Image
                  className={styles.discoverFixedMobileLeftArrowBaloon}
                  src={discoverImages.dialog7.image}
                  alt={discoverImages.dialog7.alt}
                />
              </Col>
              <Col span={2} />
            </Row>
          </Col>
        </Col>
      </Row>
    </Container>
  )
}

DiscoverContainer.propTypes = {
  discoverTitleLastChar: PropTypes.string.isRequired,
  discoverDescription: PropTypes.shape({}).isRequired,
  discoverTitleFirstPart: PropTypes.string.isRequired,
  discoverImages: PropTypes.shape({
    dialog1: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog2: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog3: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog4: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog5: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog6: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog7: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    discoverTitleFirstPart: PropTypes.string.isRequired,
  }).isRequired,
}

export default DiscoverContainer
