import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'

import { Container, Heading, Text } from '_atoms'
import { useWindowSize } from '_hooks'

import styles from './styles.module.css'

const DescriptionImage = ({
  title,
  description,
  image,
  imageDescription,
  imageSize,
  imageSizeMobile,
  reverse,
  backgroundColor,
}) => {
  const [screenWidth] = useWindowSize()
  const isMobileView = screenWidth < 1024

  return (
    <div style={{ backgroundColor }}>
      <Container>
        <div
          className={styles.container}
          style={{ flexDirection: reverse ? 'row-reverse' : 'row' }}
        >
          <div className={styles.description}>
            <div>
              <Fade distance="10%" bottom>
                <Heading type="h4" bold className={styles.heading}>
                  {title}
                  <span className={styles.dotSecondary}>.</span>
                </Heading>
              </Fade>
            </div>
            <div>
              <Fade delay={200} distance="10%" bottom>
                <Text color="dark" size="32" splitParagraphs className={styles.paragraph}>
                  {description}
                </Text>
              </Fade>
            </div>
          </div>
          <div
            style={{ width: `${isMobileView ? imageSizeMobile : imageSize}%` }}
            className={styles.image}
          >
            <Fade delay={450} distance="20%" bottom>
              <Img
                fluid={image}
                alt={imageDescription}
                imgStyle={{
                  objectFit: 'cover',
                  position: 'relative',
                }}
                fadeIn={false}
              />
            </Fade>
          </div>
        </div>
      </Container>
    </div>
  )
}

DescriptionImage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  imageDescription: PropTypes.string.isRequired,
  imageSize: PropTypes.string.isRequired,
  imageSizeMobile: PropTypes.string.isRequired,
  reverse: PropTypes.bool,
  backgroundColor: PropTypes.string.isRequired,
}

DescriptionImage.defaultProps = {
  reverse: undefined,
}

export default DescriptionImage
