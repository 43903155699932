import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import { Image, Text } from '_atoms'

import styles from './styles.module.css'

const LysPanel = ({ panelTitle, panelDescription, panelIllustration, panelIllustrationAlt }) => {
  return (
    <div className={styles.panelContainer}>
      <div className={styles.content}>
        <div className={styles.containerDescription}>
          <Fade delay={200} distance="10%" bottom>
            <Text className={styles.title}>
              {panelTitle}
              <span className={styles.dot}>.</span>
            </Text>
            <Text className={styles.description}>{panelDescription}</Text>
          </Fade>
        </div>
        <div className={styles.containerIllustration}>
          <Fade delay={450} distance="20%" bottom>
            <Image
              src={panelIllustration}
              alt={panelIllustrationAlt}
              className={styles.adminIllustration}
            />
          </Fade>
        </div>
      </div>
    </div>
  )
}
LysPanel.propTypes = {
  panelTitle: PropTypes.string.isRequired,
  panelDescription: PropTypes.string.isRequired,
  panelIllustration: PropTypes.string.isRequired,
  panelIllustrationAlt: PropTypes.string.isRequired,
}
export default LysPanel
