import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'
import Fade from 'react-reveal/Fade'

import { Image } from '_atoms'

import styles from './styles.module.css'

const EchoDesktopDiscover2Images = ({ shouldAnimate, discoverImages }) => {
  return (
    <div className={styles.discoverDialogSimpleContainer}>
      <Row style={{ width: '100%' }}>
        <Col span={5} />
        <Col span={7}>
          <Fade delay={450} opposite when={shouldAnimate} distance="20%" bottom>
            <Image src={discoverImages.dialog4.image} alt={discoverImages.dialog4.alt} />
          </Fade>
        </Col>
      </Row>
      <Row style={{ width: '100%' }}>
        <Col span={7}>
          <Fade delay={650} opposite when={shouldAnimate} distance="20%" bottom>
            <Image src={discoverImages.dialog5.image} alt={discoverImages.dialog5.alt} />
          </Fade>
        </Col>
        <Col span={5} />
      </Row>
      <Row style={{ width: '100%' }}>
        <Col span={5} />
        <Col span={7}>
          <Fade delay={750} opposite when={shouldAnimate} distance="20%" bottom>
            <Image src={discoverImages.dialog6.image} alt={discoverImages.dialog6.alt} />
          </Fade>
        </Col>
      </Row>
      <Row style={{ width: '100%' }}>
        <Col span={7}>
          <Fade delay={850} opposite when={shouldAnimate} distance="20%" bottom>
            <Image src={discoverImages.dialog7.image} alt={discoverImages.dialog7.alt} />
          </Fade>
        </Col>
        <Col span={5} />
      </Row>
    </div>
  )
}
EchoDesktopDiscover2Images.propTypes = {
  shouldAnimate: PropTypes.bool.isRequired,
  discoverImages: PropTypes.shape({
    dialog1: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog2: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog3: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog4: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog5: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog6: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog7: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
  }).isRequired,
}

export default EchoDesktopDiscover2Images
