import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'

import { Text } from '_atoms'

import styles from './styles.module.css'

const LysChallenge = ({
  challengeIllustration,
  challengeIllustrationAlt,
  challengeTitle,
  challengeFirstParagraph,
  challengeSecondParagraph,
  challengeThirdParagraph,
}) => {
  return (
    <div className={styles.challengeContainer}>
      <div className={styles.container}>
        <div className={styles.containerIllustration}>
          <Fade delay={200} distance="10%" bottom>
            <Img
              fluid={challengeIllustration}
              alt={challengeIllustrationAlt}
              fadeIn={false}
              className={styles.challengeIllustration}
            />
          </Fade>
        </div>
        <div className={styles.containerDescription}>
          <Fade delay={450} distance="20%" bottom>
            <Text className={styles.title}>
              {challengeTitle}
              <span className={styles.dot}>.</span>
            </Text>
            <Text className={styles.description}>{challengeFirstParagraph}</Text>
            <Text className={styles.description}>{challengeSecondParagraph}</Text>
            <Text className={styles.description}>{challengeThirdParagraph}</Text>
          </Fade>
        </div>
      </div>
    </div>
  )
}

LysChallenge.propTypes = {
  challengeIllustration: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  challengeIllustrationAlt: PropTypes.string.isRequired,
  challengeTitle: PropTypes.string.isRequired,
  challengeFirstParagraph: PropTypes.string.isRequired,
  challengeSecondParagraph: PropTypes.string.isRequired,
  challengeThirdParagraph: PropTypes.string.isRequired,
}
export default LysChallenge
