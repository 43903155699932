import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import { Container, Text, Heading, Image } from '_atoms'

import styles from './styles.module.css'

const MoveResults = ({
  iconAES,
  iconAESAlt,
  resultsTitle,
  resultTitle1,
  resultDescription1A,
  resultDescription1B,
  resultDescription1C,
  iconExperience,
  iconExperienceAlt,
  resultTitle2,
  resultDescription2A,
  resultDescription2B,
}) => {
  return (
    <div className={styles.resultsBlock}>
      <Container className={styles.resultsContainer}>
        <div className={styles.resultsItem}>
          <Fade delay={350} distance="20%" bottom>
            <Heading type="h4" color="white" bold>
              {resultsTitle}
              <Text className={styles.dot}>.</Text>
            </Heading>
          </Fade>
        </div>
        <div className={styles.resultsItem}>
          <Image src={iconAES} alt={iconAESAlt} className={styles.resultsItemImage} />

          <div className={styles.resultsText}>
            <Fade delay={200} distance="10%" bottom>
              <Text size="28" color="white" bold className={styles.resultsTitle}>
                {resultTitle1}
              </Text>
              <Text size="18" className={styles.resultsText}>
                {resultDescription1A}
                <b>{resultDescription1B}</b>
                {resultDescription1C}
              </Text>
            </Fade>
          </div>
        </div>
        <div className={styles.resultsItem}>
          <Image src={iconExperience} alt={iconExperienceAlt} className={styles.resultsItemImage} />

          <div className={styles.resultsText}>
            <Fade delay={200} distance="10%" bottom>
              <Text size="28" color="white" bold className={styles.resultsTitle}>
                {resultTitle2}
              </Text>
              <Text size="18" className={styles.resultsText}>
                <b> {resultDescription2A}</b>
                {resultDescription2B}
              </Text>
            </Fade>
          </div>
        </div>
      </Container>
    </div>
  )
}
MoveResults.propTypes = {
  iconAES: PropTypes.string.isRequired,
  iconAESAlt: PropTypes.string.isRequired,
  resultsTitle: PropTypes.string.isRequired,
  resultTitle1: PropTypes.string.isRequired,
  resultDescription1A: PropTypes.string.isRequired,
  resultDescription1B: PropTypes.string.isRequired,
  resultDescription1C: PropTypes.string.isRequired,
  iconExperience: PropTypes.string.isRequired,
  iconExperienceAlt: PropTypes.string.isRequired,
  resultTitle2: PropTypes.string.isRequired,
  resultDescription2A: PropTypes.string.isRequired,
  resultDescription2B: PropTypes.string.isRequired,
}

export default MoveResults
