import React from 'react'
import PropTypes from 'prop-types'
import { path } from 'ramda'

import { JobCard } from '_molecules'

import Empty from './empty'
import styles from './styles.module.css'

const OpeningsSection = ({ jobs }) => (
  <div className={styles.jobsWrapper}>
    {jobs.map(item => (
      <JobCard
        key={`${path(['title'], item)}-${path(['hosted_url'], item)}`}
        title={path(['title'], item)}
        city={path(['location', 'city'], item)}
        state={path(['location', 'state'], item)}
        href={path(['hosted_url'], item)}
      />
    ))}
  </div>
)

OpeningsSection.propTypes = {
  jobs: PropTypes.arrayOf(Object),
}

OpeningsSection.defaultProps = {
  jobs: [],
}

OpeningsSection.Empty = Empty

export default OpeningsSection
