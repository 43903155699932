import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import { SliderNav, Container } from '_atoms'
import { TinySlider, Quote } from '_molecules'

import styles from './styles.module.css'

const TestimonialsCresol = ({ TESTIMONIALS_SLIDER_OPTIONS, testimonials }) => {
  return (
    <Container className={styles.testimonialsContainer}>
      <Fade distance="10%" bottom>
        <TinySlider id="testimonials" options={TESTIMONIALS_SLIDER_OPTIONS}>
          {testimonials.map(quote => (
            <div key={`home-quote-${quote.id}`}>
              <Quote
                mobileComplete
                color="skyroamSecondary"
                text={quote.text.internal.content}
                user={quote.user}
                userPhoto={quote.userImage.fixed}
                userJob={quote.jobDescription}
                imageClassName={styles.testimonialsImage}
                className={styles.quote}
              />
            </div>
          ))}
        </TinySlider>
        <div id="testimonials-nav" className={styles.testimonialsIcons}>
          {testimonials.map(({ id }) => (
            <SliderNav key={id} className={styles.testimonialsNav} />
          ))}
        </div>
      </Fade>
    </Container>
  )
}

TestimonialsCresol.propTypes = {
  TESTIMONIALS_SLIDER_OPTIONS: PropTypes.PropTypes.shape({
    autoplay: PropTypes.bool,
    autoplayHoverPause: PropTypes.bool,
    autoplayText: PropTypes.arrayOf(PropTypes.string),
    autoplayTimeout: PropTypes.number,
    center: PropTypes.bool,
    controls: PropTypes.bool,
    loop: PropTypes.bool,
    mouseDrag: PropTypes.bool,
    navContainer: PropTypes.string,
  }).isRequired,
  testimonials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default TestimonialsCresol
