import React from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'

import { Container, Heading, BlueText, Image } from '_atoms'
import equalOpportunities from '_images/pages/careers/equal-opportunities.png'

import styles from './styles.module.css'

const EqualOpportunities = () => (
  <div className={styles.equalOppotunitiesConatiner}>
    <Container>
      <Heading type="h2" className={styles.title}>
        <FormattedMessage id="careers.opportunities.title" />
        <BlueText squared>.</BlueText>
      </Heading>
    </Container>
    <Image src={equalOpportunities} alt="Equal opportunities section image" />
    <Container>
      <Heading type="h3" className={styles.subTitle}>
        <FormattedMessage id="careers.opportunities.subTitle" />{' '}
        <a href="mailto:careers@cheesecakelabs.com" className={styles.mailTo}>
          <strong>careers@cheesecakelabs.com.</strong>
        </a>
      </Heading>
    </Container>
  </div>
)

export default EqualOpportunities
