import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import { Image, Text, Container } from '_atoms'

import styles from './styles.module.css'

const QuiverQuantSimplified = ({
  thirdSectionTitleText,
  thirdSectionText,
  thirdSectionFrontCellphone,
  thirdSectionFrontCellphoneDescription,
}) => {
  return (
    <div className={styles.outContainer}>
      <Container className={styles.innerContainer}>
        <Fade delay={200} distance="10%" bottom>
          <div className={styles.leftSide}>
            <Image
              alt={thirdSectionFrontCellphoneDescription}
              src={thirdSectionFrontCellphone}
              className={styles.thirdSectionFrontCellphone}
            />
          </div>
        </Fade>
        <Fade delay={450} distance="20%" bottom>
          <div className={styles.rightSide}>
            <Text className={styles.thirdSectionTitle}>
              {thirdSectionTitleText}
              <span className={styles.dot}>.</span>
            </Text>
            <Text color="white" splitParagraphs className={styles.description}>
              {thirdSectionText}
            </Text>
          </div>
        </Fade>
      </Container>
    </div>
  )
}

QuiverQuantSimplified.propTypes = {
  thirdSectionFrontCellphoneDescription: PropTypes.string.isRequired,
  thirdSectionTitleText: PropTypes.string.isRequired,
  thirdSectionText: PropTypes.string.isRequired,
  thirdSectionFrontCellphone: PropTypes.string.isRequired,
  thirdSectionBackgroundImg: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  thirdSectionBackgroundImgMobile: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
}

export default QuiverQuantSimplified
