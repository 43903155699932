import React from 'react'
import PropTypes from 'prop-types'
import CookieConsent from 'react-cookie-consent'
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl'

import variables from '_config/css-variables'

import styles from './styles.module.css'

const BUTTON_STYLES = {
  color: variables['color-white'],
  border: `1px solid ${variables['color-white']}`,
  background: variables['color-primary'],
}

const hasAccepted =
  typeof document !== `undefined` ? document.cookie.indexOf('cookies_accepted') > -1 : null

const CookieConsentBar = ({ intl }) =>
  !hasAccepted && (
    <CookieConsent
      style={{ background: variables['color-dark-bg'] }}
      containerClasses={styles.cookieConsent}
      buttonText={intl.formatMessage({ id: 'cookieConsent.accept' })}
      buttonStyle={BUTTON_STYLES}
    >
      <FormattedMessage id="cookieConsent.text1" />{' '}
      <a
        href="https://content.cheesecakelabs.com/privacy-policy"
        rel="noopener noreferrer"
        target="_blank"
      >
        <FormattedMessage id="cookieConsent.text2" />
      </a>
      .
    </CookieConsent>
  )

CookieConsentBar.propTypes = {
  intl: PropTypes.instanceOf(Object).isRequired,
}

export default injectIntl(CookieConsentBar)
