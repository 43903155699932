import React from 'react'
import Fade from 'react-reveal/Fade'
import { Row, Col } from 'react-simple-flex-grid'
import PropTypes from 'prop-types'

import { Container, Heading, Text, BlueText, Image } from '_atoms'

import styles from './styles.module.css'

const Engagements = ({ title, engagements }) => (
  <section className={styles.engagements}>
    <Container>
      <Fade distance="20%" delay={200} bottom>
        <Heading type="h4" color="white" bold className={styles.engagementsTitle}>
          {title}
          <BlueText squared>.</BlueText>
        </Heading>
      </Fade>

      <Row>
        {engagements.map((engagement, index) => (
          <Col
            xs={12}
            sm={6}
            md={6}
            lg={6}
            key={engagement.title}
            className={styles.engagementsEngagement}
          >
            <Fade distance="20%" delay={(index + 1) * 200} bottom>
              <Image src={engagement.image.file.url} alt={engagement.image.description} />
              <Text size="32" bold color="white" className={styles.engagementsEngagementTitle}>
                {engagement.title}
                <BlueText squared>.</BlueText>
              </Text>
              <Text size="22" color="white" className={styles.engagementsEngagementText}>
                {engagement.description.internal.content}
              </Text>
            </Fade>
          </Col>
        ))}
      </Row>
    </Container>
  </section>
)

Engagements.propTypes = {
  title: PropTypes.string.isRequired,
  engagements: PropTypes.arrayOf(Object).isRequired,
}

export default Engagements
