import React from 'react'
import { Row, Col } from 'react-simple-flex-grid'
import PropTypes from 'prop-types'

import { Container, Image, Heading } from '_atoms'

import TextJSON from '../../../text-json'

import styles from './styles.module.css'

const MeliChallengeContainer = ({
  challangeTitleFirstPart,
  challangeTitleLastChar,
  challangeDescription,
  challengeImages,
}) => {
  return (
    <Container>
      <Row>
        <Col xs={12} sm={12} md={5} lg={5}>
          <div className={styles.challengeContent}>
            <Heading className={styles.challengeTitle} type="h1" color="white" bold>
              {challangeTitleFirstPart}
              <span className={styles.challengeTitleLastChar}>{challangeTitleLastChar}</span>
            </Heading>
            <TextJSON color="white" size="22" className={styles.challengeParagraph}>
              {challangeDescription}
            </TextJSON>
          </div>

          <div className={styles.challengeFixedMobile}>
            <Col>
              <Row style={{ zIndex: -4 }}>
                <Image
                  src={challengeImages.backBallon.image}
                  alt={challengeImages.backBallon.alt}
                />
              </Row>

              <Row>
                <Image
                  id="afd"
                  src={challengeImages.frontBallon.image}
                  alt={challengeImages.frontBallon.alt}
                />
              </Row>
            </Col>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

MeliChallengeContainer.propTypes = {
  challengeImages: PropTypes.shape({
    backBallon: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    frontBallon: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
  }).isRequired,
  challangeTitleFirstPart: PropTypes.string.isRequired,
  challangeTitleLastChar: PropTypes.string.isRequired,
  challangeDescription: PropTypes.shape({}).isRequired,
}

export default MeliChallengeContainer
