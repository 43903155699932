import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { FormattedMessage } from 'gatsby-plugin-intl'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'

import { Container, Heading, ScrollArrow, BlueText, Button } from '_atoms'

import styles from './styles.module.css'

const HERO_IMAGE_STYLES = {
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}

const cx = classNames.bind(styles)

const Hero = ({
  title,
  subTitle,
  heroImageDesktop,
  heroImageMobile,
  hasScrollArrow,
  buttonText,
  buttonLink,
  buttonOnClick,
  buttonClass,
  className,
  breakline,
  sliderText,
  scrollArrowText,
  contentClassName,
  titleClassName,
  subtitleClassName,
  onScrollArrowClick,
  scrollArrowButton,
  customContent,
}) => (
  <header
    className={cx('header', className, {
      small: !buttonText && !hasScrollArrow,
    })}
  >
    {heroImageDesktop && (
      <Img
        fluid={heroImageDesktop.fluid}
        style={HERO_IMAGE_STYLES}
        alt={heroImageDesktop.description}
        className={cx('heroImage', 'heroImageDesktop')}
      />
    )}
    {heroImageMobile && (
      <Img
        fluid={heroImageMobile.fluid}
        style={HERO_IMAGE_STYLES}
        alt={heroImageMobile.description}
        className={cx('heroImage', 'heroImageMobile')}
      />
    )}

    <Container className={styles.hero}>
      <div className={contentClassName}>
        {customContent || (
          <>
            <Fade distance="25%" top>
              <Heading
                type="h1"
                color="white"
                bold
                className={cx('title', titleClassName, { breakline })}
              >
                <FormattedMessage
                  id={title}
                  values={{
                    span: (...chunks) => {
                      const textId = (chunks[0] || '').toLowerCase()
                      return <span id={`header-text-${textId.replace(/ /g, '-')}`}>{chunks}</span>
                    },
                  }}
                />
                <BlueText light squared>
                  .
                </BlueText>
              </Heading>
            </Fade>

            {subTitle && (
              <Fade distance="25%" bottom>
                <div className={cx({ [styles.sliderWrapper]: sliderText })}>
                  <Heading
                    type="h2"
                    color="white"
                    className={cx(styles.subTitle, subtitleClassName, {
                      [styles.slider]: sliderText,
                    })}
                  >
                    <FormattedMessage id={subTitle} />
                    {sliderText && <div className={styles.sliderTextWrapper}>{sliderText}</div>}
                  </Heading>
                </div>
              </Fade>
            )}

            {buttonText && (
              <Fade delay={200} bottom>
                <div className={styles.openingsButton}>
                  <a {...(buttonLink ? { href: buttonLink } : {})} className={buttonClass}>
                    <Button onClick={buttonOnClick} type="outlineWhite">
                      <FormattedMessage id={buttonText} />
                    </Button>
                  </a>
                </div>
              </Fade>
            )}
          </>
        )}

        {hasScrollArrow &&
          (scrollArrowButton ? (
            <Fade delay={200} bottom>
              <button
                className={styles.heroButton}
                type="button"
                onClick={() => onScrollArrowClick()}
              >
                <ScrollArrow text={scrollArrowText} className={styles.heroArrow} />
              </button>
            </Fade>
          ) : (
            <Fade delay={200} bottom>
              <ScrollArrow
                className={styles.arrowButton}
                text={scrollArrowText}
                onClick={() => onScrollArrowClick()}
              />
            </Fade>
          ))}
      </div>
    </Container>
  </header>
)

Hero.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  heroImageDesktop: PropTypes.shape({
    description: PropTypes.string,
    fluid: PropTypes.shape({ src: PropTypes.string, srcSet: PropTypes.string }),
  }),
  heroImageMobile: PropTypes.shape({
    description: PropTypes.string,
    fluid: PropTypes.shape({ src: PropTypes.string, srcSet: PropTypes.string }),
  }),
  breakline: PropTypes.bool,
  hasScrollArrow: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonOnClick: PropTypes.func,
  buttonClass: PropTypes.string,
  className: PropTypes.string,
  sliderText: PropTypes.string,
  scrollArrowText: PropTypes.string,
  contentClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  subtitleClassName: PropTypes.string,
  onScrollArrowClick: PropTypes.func,
  scrollArrowButton: PropTypes.bool,
  customContent: PropTypes.shape({}),
}

Hero.defaultProps = {
  title: undefined,
  subTitle: undefined,
  heroImageDesktop: undefined,
  heroImageMobile: undefined,
  breakline: false,
  hasScrollArrow: false,
  buttonText: undefined,
  buttonLink: undefined,
  buttonOnClick: () => {},
  buttonClass: undefined,
  className: undefined,
  sliderText: undefined,
  scrollArrowText: '',
  contentClassName: undefined,
  titleClassName: undefined,
  subtitleClassName: undefined,
  onScrollArrowClick: () => {},
  scrollArrowButton: false,
  customContent: undefined,
}

export default Hero
