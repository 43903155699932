import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import { Image, Text } from '_atoms'

import styles from './styles.module.css'

const LysBrands = ({
  brandsTitle,
  brandsDescription,
  brandsIllustration,
  brandsIllustrationAlt,
}) => {
  return (
    <div className={styles.backgroundDiagonal}>
      <div className={styles.content}>
        <div className={styles.containerDescription}>
          <Fade delay={200} distance="10%" bottom>
            <Text className={styles.title}>
              {brandsTitle}
              <span className={styles.dot}>.</span>
            </Text>
            <Text className={styles.description}>{brandsDescription}</Text>
          </Fade>
        </div>
        <div className={styles.containerIllustration}>
          <Fade delay={450} distance="20%" bottom>
            <Image
              src={brandsIllustration}
              alt={brandsIllustrationAlt}
              className={styles.brandsIllustration}
            />
          </Fade>
        </div>
      </div>
    </div>
  )
}
LysBrands.propTypes = {
  brandsTitle: PropTypes.string.isRequired,
  brandsIllustrationAlt: PropTypes.string.isRequired,
  brandsDescription: PropTypes.string.isRequired,
  brandsIllustration: PropTypes.string.isRequired,
}
export default LysBrands
