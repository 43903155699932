import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import Img from 'gatsby-image'

import variables from '_config/css-variables'
import { Image, Container, Text, ScrollArrow, Heading } from '_atoms'
import { NavLink } from '_molecules'
import AppStoreBadge from '_images/svgs/badge-app-store.svg'
import PlayStoreBadge from '_images/svgs/badge-play-store.svg'

import style from './styles.module.css'

const BluebenxHero = ({
  bluebenxLogo,
  bluebenxLogoAlt,
  heroTitle,
  heroDescription,
  heroMobileScreen,
  heroMobileScreenAlt,
  heroBackground,
}) => {
  return (
    <div className={style.hero} style={{ backgroundImage: `url(${heroBackground})` }}>
      <Container className={style.heroContainer}>
        <div className={style.heroInfo}>
          <div className={style.heroContent}>
            <div className={style.heroDescriptionWrapper}>
              <Fade delay={200} distance="10%" bottom>
                <Img fluid={bluebenxLogo} className={style.heroLogo} alt={bluebenxLogoAlt} />
                <Heading type="h1" color={variables['color-white']} className={style.heroTitle}>
                  {heroTitle}
                  <span className={style.dotSecondary}>.</span>
                </Heading>
                <Text
                  color={variables['color-white']}
                  size="22"
                  splitParagraphs
                  className={style.heroDescription}
                >
                  {heroDescription}
                </Text>
                <div className={style.storeBtns}>
                  <NavLink
                    hasPrefix={false}
                    to="https://apps.apple.com/br/app/bluebenx/id1512813493"
                  >
                    <AppStoreBadge />
                  </NavLink>
                  <NavLink
                    to="https://play.google.com/store/apps/details?id=br.com.bluebenx"
                    hasPrefix={false}
                  >
                    <PlayStoreBadge />
                  </NavLink>
                </div>
              </Fade>
              <ScrollArrow
                isButton
                scrollId="vision"
                className={style.heroScrollArrow}
                scrollOffset={70}
              />
            </div>
          </div>
          <div className={style.heroImage}>
            <Fade delay={450} distance="20%" bottom>
              <Image
                src={heroMobileScreen}
                className={style.heroMobileScreen}
                alt={heroMobileScreenAlt}
              />
            </Fade>
          </div>
        </div>
      </Container>
    </div>
  )
}

BluebenxHero.propTypes = {
  bluebenxLogo: PropTypes.shape({
    description: PropTypes.string,
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      sizes: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcSetWebp: PropTypes.string,
      srcWebp: PropTypes.string,
    }),
  }).isRequired,
  bluebenxLogoAlt: PropTypes.string.isRequired,
  heroTitle: PropTypes.string.isRequired,
  heroDescription: PropTypes.string.isRequired,
  heroMobileScreen: PropTypes.string.isRequired,
  heroMobileScreenAlt: PropTypes.string.isRequired,
  heroBackground: PropTypes.string.isRequired,
}

export default BluebenxHero
