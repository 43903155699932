import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import { Container, Image, Text } from '_atoms'

import styles from './styles.module.css'

const ResultsCresol = ({
  textOneBlockOneResults,
  resultTitle,
  textOrangeBlockOneResults,
  textTwoBlockOneResults,
  textOneBlockTwoResults,
  textOrangeBlockTwoResults,
  textTwoBlockTwoResults,
  storesIcon,
  storesIconDescription,
  downloadIcon,
  downloadIconDescription,
}) => {
  return (
    <div className={styles.resultsBlock}>
      <Container className={styles.resultsContainer}>
        <div className={styles.resultsItem}>
          <div className={styles.resultsText}>
            <Fade distance="10%" bottom>
              <Text size="48" bold className={styles.resultsText}>
                {resultTitle}
                <span className={styles.dotSecondary}>.</span>
              </Text>
            </Fade>
          </div>
        </div>
        <div className={styles.resultsItem}>
          <div className={styles.resultsText}>
            <Fade distance="10%" bottom>
              <Text size="22" className={styles.resultsText}>
                <Image
                  src={storesIcon}
                  alt={storesIconDescription}
                  className={styles.resultsIcon}
                />
                <br />
                {textOneBlockOneResults}
                <b>{textOrangeBlockOneResults}</b>
                {textTwoBlockOneResults}
              </Text>
            </Fade>
          </div>
        </div>
        <div className={styles.resultsItem}>
          <div className={styles.resultsText}>
            <Fade delay={200} distance="10%" bottom>
              <Text size="22" className={styles.resultsText}>
                <Image
                  src={downloadIcon}
                  alt={downloadIconDescription}
                  className={styles.resultsIcon}
                />
                <br />
                {textOneBlockTwoResults}
                <b>{textOrangeBlockTwoResults}</b>
                {textTwoBlockTwoResults}
              </Text>
            </Fade>
          </div>
        </div>
      </Container>
    </div>
  )
}

ResultsCresol.propTypes = {
  textOneBlockOneResults: PropTypes.string.isRequired,
  resultTitle: PropTypes.string.isRequired,
  textOrangeBlockOneResults: PropTypes.string.isRequired,
  textTwoBlockOneResults: PropTypes.string.isRequired,
  textOneBlockTwoResults: PropTypes.string.isRequired,
  textOrangeBlockTwoResults: PropTypes.string.isRequired,
  textTwoBlockTwoResults: PropTypes.string.isRequired,
  storesIcon: PropTypes.string.isRequired,
  storesIconDescription: PropTypes.string.isRequired,
  downloadIcon: PropTypes.string.isRequired,
  downloadIconDescription: PropTypes.string.isRequired,
}

export default ResultsCresol
