import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import variables from '_config/css-variables'
import { Container, Text } from '_atoms'

import style from './styles.module.css'

const BluebenxMiddle = ({ middleText, middleBackground }) => {
  return (
    <div className={style.middle} style={{ backgroundImage: `url(${middleBackground})` }}>
      <Container className={style.middleContainer}>
        <Fade delay={200} distance="10%" bottom>
          <Text
            color={variables['color-white']}
            size="22"
            splitParagraphs
            className={style.middleText}
          >
            {middleText}
          </Text>
        </Fade>
      </Container>
    </div>
  )
}

BluebenxMiddle.propTypes = {
  middleText: PropTypes.string.isRequired,
  middleBackground: PropTypes.string.isRequired,
}

export default BluebenxMiddle
