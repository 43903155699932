import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import Fade from 'react-reveal/Fade'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { Row, Col } from 'react-simple-flex-grid'
import Img from 'gatsby-image'
import { v4 as uuidv4 } from 'uuid'

import { Button, Container } from '_atoms'

import ViewportObserver from '../viewport-observer'

import styles from './styles.module.css'

const cx = classNames.bind(styles)

const CaseSliderItem = ({ name, title, color, link, scroll, onViewport, children, linkId }) => {
  const columnsSize = {
    desktop: {
      xl: 5,
      lg: 5,
      sm: 6,
      md: 5,
    },
    image: {
      xs: 12,
      sm: 6,
      md: 7,
      lg: 7,
      xl: 7,
    },
  }

  return (
    <section
      className={cx('caseSliderItem', 'section')}
      style={{ backgroundColor: color }}
      id={name}
    >
      <Container className={cx('caseContainer')}>
        <div className={styles.caseSliderContent}>
          <Row align="middle">
            <Col className={styles.desktopVersion} {...columnsSize.desktop}>
              <div>
                <Fade delay={200} bottom>
                  <h3>
                    {title.map(item =>
                      item.type === 'text' ? (
                        <div key={`text-${uuidv4()}`} className={styles.caseSliderContentText}>
                          {item.content}
                        </div>
                      ) : (
                        <div key={`text-${uuidv4()}`}>
                          <item.ImageComponent className={styles.caseBrand} />
                        </div>
                      )
                    )}
                    <span className={styles.dot}>.</span>
                  </h3>
                </Fade>

                <Fade delay={200} bottom>
                  <div className={styles.viewCaseButton}>
                    <Button
                      type="outlineWhite"
                      href={link}
                      linkClass="caseSliderItemLinkClass"
                      linkId={`${name}_case`}
                    >
                      <FormattedMessage id="home.cases.viewCaseButton" />
                    </Button>
                  </div>
                </Fade>
              </div>
            </Col>
            <Col className={styles.mobileVersion} xs={12}>
              <div>
                <h3 className={styles.caseTitle}>
                  {title.map(item =>
                    item.type === 'text' ? (
                      <div key={`text-${uuidv4()}`} className={styles.caseSliderContentText}>
                        {item.content}
                      </div>
                    ) : (
                      <div key={`text-${uuidv4()}`}>
                        <item.ImageComponent />
                      </div>
                    )
                  )}
                  <span className={styles.dot}>.</span>
                </h3>

                <Fade delay={200} bottom>
                  <div className={styles.viewCaseButton}>
                    <Button
                      type="outlineWhite"
                      href={link}
                      linkClass="caseSliderItemLinkClass"
                      linkId={linkId}
                    >
                      <FormattedMessage id="home.cases.viewCaseButton" />
                    </Button>
                  </div>
                </Fade>
              </div>
            </Col>

            <Col {...columnsSize.image}>
              <Fade delay={350} distance="20%" bottom>
                {children}
              </Fade>
            </Col>
          </Row>
        </div>
      </Container>

      {!!scroll && <ViewportObserver scroll={scroll} onViewport={onViewport} />}
    </section>
  )
}

CaseSliderItem.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  title: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['text', 'image']),
      content: PropTypes.string,
      ImageComponent: PropTypes.func,
    })
  ).isRequired,
  link: PropTypes.string.isRequired,
  scroll: PropTypes.number,
  onViewport: PropTypes.func,
  children: PropTypes.node.isRequired,
  linkId: PropTypes.string,
}

CaseSliderItem.defaultProps = {
  scroll: undefined,
  onViewport: () => {},
  linkId: undefined,
}

const CaseSliderItemImg = ({ fluid, alt, maxWidth, className }) => (
  <div className={cx('caseSliderItemImg', className)} style={{ maxWidth }}>
    <Img fluid={fluid} imgStyle={{ objectFit: 'contain' }} alt={alt} />
  </div>
)

CaseSliderItemImg.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  fluid: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
  maxWidth: PropTypes.number.isRequired,
  className: PropTypes.string,
}

CaseSliderItemImg.defaultProps = {
  className: '',
}

CaseSliderItem.Img = CaseSliderItemImg

export default CaseSliderItem
