import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

const BrandsFluid = ({ name, className }) => {
  const {
    allFile: { edges: brands },
  } = useStaticQuery(graphql`
    {
      allFile(filter: { absolutePath: { regex: "/images/brands/" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 210, quality: 80) {
                originalName
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const imageSrc = useMemo(
    () => brands.find(({ node }) => `${name}.png` === node.childImageSharp.fluid.originalName),
    [brands, name]
  )

  return (
    <div className={className}>
      <Img
        fluid={imageSrc.node.childImageSharp.fluid}
        imgStyle={{ objectFit: 'contain' }}
        alt={name}
        fadeIn={false}
      />
    </div>
  )
}

BrandsFluid.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
}

BrandsFluid.defaultProps = {
  className: '',
}

export default BrandsFluid
