import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'
import Fade from 'react-reveal/Fade'
import classNames from 'classnames'

import { Container, Heading, BlueText, Text } from '_atoms'

import styles from './styles.module.css'

const AndroidDevelopmentResults = ({ resultsData, resultsTitle, resultsDescription }) => {
  const getDot = (t, path) => path.split('.').reduce((r, k) => r?.[k], t)
  return (
    <section className={styles.results}>
      <div className={styles.resultsBg}>
        <Container className={styles.resultsContentWrapper}>
          <Row>
            <Col xs={12} sm={12} md={3} lg={3} className={styles.resultsColumnWrapper}>
              <Fade distance="10%" bottom>
                <Heading type="h5" bold className={styles.resultsHeading}>
                  {resultsTitle}
                  <BlueText light className={styles.blueDot}>
                    .
                  </BlueText>
                </Heading>
                <Text className={styles.resultsDescription}>{resultsDescription}</Text>
              </Fade>
            </Col>
          </Row>
          <Row>
            <div className={styles.resultsTextRowWrapper}>
              {resultsData.map((result, index) => (
                <Row
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`Results ${index}`}
                  className={classNames(
                    styles.resultsTextWrapper,
                    getDot(styles, `resultsBlockData${index}`)
                  )}
                >
                  <Fade distance="10%" bottom delay={(index + 1) * 200}>
                    <Col span={3}>
                      <Text className={styles.resultsTextTitle}>{result.title}</Text>
                      <Text className={styles.resultsValue} bold>
                        {result.value}
                      </Text>

                      <Text size="18" className={styles.resultsText}>
                        {result.description}
                      </Text>
                    </Col>
                  </Fade>
                </Row>
              ))}
            </div>
          </Row>
        </Container>
      </div>
    </section>
  )
}

AndroidDevelopmentResults.propTypes = {
  resultsTitle: PropTypes.string.isRequired,
  resultsDescription: PropTypes.string.isRequired,
  resultsData: PropTypes.arrayOf({
    title: PropTypes.string,
    value: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
}

export default AndroidDevelopmentResults
