import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'
import { useIntl, Link } from 'gatsby-plugin-intl'

import { Container, Image, Heading, Button } from '_atoms'

import TextJSON from '../../../text-json'

import styles from './styles.module.css'

const CallToActionContainer = ({
  callToActionTitleFirstPart,
  callToActionTitleLastChar,
  callToActionDescription,
  callToActionImages,
}) => {
  const intl = useIntl()

  return (
    <Container>
      <Row>
        <Col xs={12} sm={12} md={5} lg={5}>
          <div className={styles.callToActionContent}>
            <Heading className={styles.callToActionTitle} type="h4" color="white" bold>
              {callToActionTitleFirstPart}
              <span className={styles.callToActionTitleLastChar}>{callToActionTitleLastChar}</span>
            </Heading>
            <TextJSON color="white" size="22" className={styles.callToActionParagraph}>
              {callToActionDescription}
            </TextJSON>

            <Link to="/contact">
              <Button className={styles.callToActionButton}>
                {intl.formatMessage({ id: 'contact.hero.title' })}
              </Button>
            </Link>

            <div className={styles.mobileActionsImages}>
              <Row style={{ marginTop: 80 }}>
                <Col span={6}>
                  <Image
                    src={callToActionImages.controller.image}
                    alt={callToActionImages.controller.alt}
                  />
                </Col>
                <Col span={6} style={{ zIndex: 100 }}>
                  <Image src={callToActionImages.phone.image} alt={callToActionImages.phone.alt} />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
CallToActionContainer.propTypes = {
  callToActionTitleFirstPart: PropTypes.string.isRequired,
  callToActionTitleLastChar: PropTypes.string.isRequired,
  callToActionDescription: PropTypes.shape({}).isRequired,
  callToActionImages: PropTypes.shape({
    controller: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    phone: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
  }).isRequired,
}
export default CallToActionContainer
