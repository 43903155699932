import React from 'react'
import { Row, Col } from 'react-simple-flex-grid'
import Fade from 'react-reveal/Fade'
import PropTypes from 'prop-types'

import { Container, Text, Image } from '_atoms'
import TextJSON from '_molecules/text-json'

import styles from './styles.module.css'

const InfoSection = ({
  iconDrivers,
  altIconDrivers,
  titleDrivers,
  descriptionDrivers,
  iconCharging,
  altIconCharging,
  titleCharging,
  descriptionCharging,
}) => {
  return (
    <Container>
      <Row className={styles.content}>
        <Col className={styles.contentLeft}>
          <Fade distance="10%" bottom>
            <Image src={iconDrivers} alt={altIconDrivers} />
            <Text size="22" className={styles.titleDrivers}>
              {titleDrivers}
            </Text>
            <TextJSON className={styles.descriptionDrivers}>{descriptionDrivers}</TextJSON>
          </Fade>
        </Col>
        <Col className={styles.contentRight}>
          <Fade distance="10%" bottom>
            <Image src={iconCharging} alt={altIconCharging} />
            <Text size="22" className={styles.titleCharging}>
              {titleCharging}
            </Text>
            <TextJSON className={styles.descriptionCharging}>{descriptionCharging}</TextJSON>
          </Fade>
        </Col>
      </Row>
    </Container>
  )
}

InfoSection.propTypes = {
  iconDrivers: PropTypes.string.isRequired,
  altIconDrivers: PropTypes.string.isRequired,
  titleDrivers: PropTypes.string.isRequired,
  descriptionDrivers: PropTypes.shape({}).isRequired,
  iconCharging: PropTypes.string.isRequired,
  altIconCharging: PropTypes.string.isRequired,
  titleCharging: PropTypes.string.isRequired,
  descriptionCharging: PropTypes.shape({}).isRequired,
}

export default InfoSection
