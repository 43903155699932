import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Img from 'gatsby-image'

import { SliderNav } from '_atoms'

import TinySlider from '../tiny-slider'

import styles from './styles.module.css'

const CarouselSlider = ({ id, options, images, className }) => (
  <div className={classnames(styles.carouselSlider, className)}>
    <TinySlider id={id} options={options}>
      {images.map(image => (
        <Img
          fluid={image.fluid}
          key={image.fluid.src}
          alt={image.description}
          imgStyle={{ position: 'relative' }}
          objectFit="contain"
        />
      ))}
    </TinySlider>
    <div id={`${id}-nav`} className={styles.carouselSliderIcons}>
      {images.map(image => (
        <SliderNav key={image.fluid.src} />
      ))}
    </div>
  </div>
)

CarouselSlider.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.shape({}).isRequired,
  images: PropTypes.arrayOf(Object).isRequired,
  className: PropTypes.string,
}

CarouselSlider.defaultProps = {
  className: undefined,
}

export default CarouselSlider
