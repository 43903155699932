import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { QUOTE_SLIDER_OPTIONS } from '_utils/config'
import { SliderNav } from '_atoms'

import TinySlider from '../tiny-slider'
import Quote from '../quote'

import styles from './styles.module.css'

const QuoteContainer = ({
  items,
  color,
  isWhite,
  isDark,
  isMultiple,
  isCenter,
  className,
  sliderClass,
  iconsClass,
  commaFormat,
  hideUserPhotoMobile,
}) => {
  return (
    <div className={styles.sliderContainer}>
      <div
        className={classNames(styles.sliderContainerWrapper, className, {
          [styles.isMultiple]: isMultiple,
        })}
      >
        <div className={styles.fadeHiddenLeft} />
        <TinySlider id="slider" options={QUOTE_SLIDER_OPTIONS} className={sliderClass}>
          {items.map(quote => {
            const text =
              typeof quote.text === 'object' ? (quote.text.internal || {}).content : quote.text
            const userPhoto = (quote.userImage || {}).fixed

            return (
              <div key={`home-quote-${quote.id}`}>
                <Quote
                  color={color}
                  white={isWhite}
                  dark={isDark}
                  center={isCenter}
                  text={text}
                  user={quote.user}
                  userPhoto={userPhoto}
                  userJob={quote.jobDescription}
                  glassdoorStars={quote.stars}
                  commaFormat={commaFormat}
                  hideUserPhotoMobile={hideUserPhotoMobile}
                />
              </div>
            )
          })}
        </TinySlider>
        <div
          id="testimonials-nav"
          className={classNames(styles.testimonialsIcons, iconsClass, {
            [styles.isCenter]: isCenter,
          })}
        >
          {items.map(({ id }) => (
            <SliderNav key={id} isWhite={isWhite} />
          ))}
        </div>
        <div className={styles.fadeHiddenRight} />
      </div>
    </div>
  )
}

QuoteContainer.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  color: PropTypes.string,
  isWhite: PropTypes.bool,
  isDark: PropTypes.bool,
  isMultiple: PropTypes.bool,
  isCenter: PropTypes.bool,
  className: PropTypes.string,
  sliderClass: PropTypes.string,
  iconsClass: PropTypes.string,
  commaFormat: PropTypes.bool,
  hideUserPhotoMobile: PropTypes.bool,
}

QuoteContainer.defaultProps = {
  items: [],
  color: 'main',
  isWhite: false,
  isDark: false,
  isMultiple: false,
  isCenter: false,
  className: undefined,
  sliderClass: undefined,
  iconsClass: undefined,
  commaFormat: false,
  hideUserPhotoMobile: false,
}

export default QuoteContainer
