import React from 'react'
import Fade from 'react-reveal/Fade'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Image } from '_atoms'

import styles from './styles.module.css'

const EchoDesktopDiscoverImages = ({ shouldAnimate, discoverImages }) => {
  return (
    <>
      <div className={styles.discoverDialogFixed}>
        <Fade delay={150} opposite when={shouldAnimate} distance="20%" bottom>
          <div className={styles.discoverDialog1}>
            <Image src={discoverImages.dialog1.image} alt={discoverImages.dialog1.alt} />
          </div>
        </Fade>
        <Fade delay={350} opposite when={shouldAnimate} distance="20%" bottom>
          <div className={styles.discoverDialog2}>
            <Image src={discoverImages.dialog2.image} alt={discoverImages.dialog2.alt} />
          </div>
        </Fade>
      </div>
      <Fade delay={550} opposite when={shouldAnimate} distance="20%" bottom>
        <div className={classnames(styles.discoverDialogFixed, styles.discoverDialog3)}>
          <Image src={discoverImages.dialog3.image} alt={discoverImages.dialog3.alt} />
        </div>
      </Fade>
    </>
  )
}

EchoDesktopDiscoverImages.propTypes = {
  shouldAnimate: PropTypes.bool.isRequired,
  discoverImages: PropTypes.shape({
    dialog1: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog2: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog3: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog4: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog5: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog6: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog7: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
  }).isRequired,
}

export default EchoDesktopDiscoverImages
