import React from 'react'
import Fade from 'react-reveal/Fade'
import { Row, Col } from 'react-simple-flex-grid'
import PropTypes from 'prop-types'

import { Container, Heading, Text, BlueText, Button } from '_atoms'

import styles from './styles.module.css'

const SideTitleText = ({ title, description, buttonText, onClick, id }) => (
  <section className={styles.sideTitleText} id="sideTitleText">
    <Container>
      <Row>
        <Col xs={12} sm={3} md={3} lg={3}>
          <Fade distance="20%" bottom>
            <Heading type="h4" color="default" bold className={styles.sideTitleTextTitle}>
              {title}
              <BlueText squared>.</BlueText>
            </Heading>
          </Fade>
        </Col>

        <Col xs={12} sm={9} md={9} lg={9}>
          <Fade distance="20%" delay={200} bottom>
            <Text
              size="20"
              className={styles.sideTitleTextDescription}
              color="dark"
              splitParagraphs
            >
              {description.internal.content}
            </Text>
            <Button type="primary" className={styles.sideTitleTextButton} onClick={onClick} id={id}>
              {buttonText}
            </Button>
          </Fade>
        </Col>
      </Row>
    </Container>
  </section>
)

SideTitleText.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({ internal: PropTypes.shape({ content: PropTypes.string }) })
    .isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
}

export default SideTitleText
