import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import { Image, Text, Container, ScrollArrow } from '_atoms'

import styles from './styles.module.css'

const QuiverQuantAbout = ({
  logoDescription,
  logo,
  aboutDescription,
  cellphones,
  cellphoneDescription,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.squareBackground} />
      <Container>
        <Fade delay={200} distance="10%" bottom>
          <div className={styles.leftSide}>
            <Image src={logo} alt={logoDescription} className={styles.imgLogo} />
            <div className={styles.containerDescription}>
              <Text color="white" splitParagraphs className={styles.description}>
                {aboutDescription}
              </Text>
            </div>

            <ScrollArrow
              isButton
              scrollId="secondSection"
              className={styles.scrollArrow}
              scrollOffset={20}
            />
          </div>
        </Fade>
        <Fade delay={450} distance="20%" bottom>
          <div className={styles.cellphones}>
            <Image alt={cellphoneDescription} src={cellphones} />
          </div>
        </Fade>
      </Container>
    </div>
  )
}

QuiverQuantAbout.propTypes = {
  logo: PropTypes.string.isRequired,
  logoDescription: PropTypes.string.isRequired,
  cellphoneDescription: PropTypes.string.isRequired,
  aboutDescription: PropTypes.string.isRequired,
  cellphones: PropTypes.string.isRequired,
}

export default QuiverQuantAbout
