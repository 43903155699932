import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'

import { Text, ScrollArrow } from '_atoms'

import styles from './styles.module.css'

const LysAbout = ({
  logoLys,
  logoLysAlt,
  firstDescription,
  secondDescription,
  firstWebPhone,
  firstWebPhoneAlt,
}) => {
  return (
    <div className={styles.aboutContainer}>
      <div className={styles.container}>
        <div className={styles.containerDescription}>
          <Fade delay={200} distance="10%" bottom>
            <Img fluid={logoLys} fadeIn={false} alt={logoLysAlt} className={styles.logoLys} />
            <Text className={styles.description}>{firstDescription}</Text>
            <Text className={styles.descriptionLast}>{secondDescription}</Text>
            <ScrollArrow isButton scrollId="challenge" scrollOffset={70} className={styles.arrow} />
          </Fade>
        </div>
        <div className={styles.containerPhone}>
          <Fade delay={450} distance="20%" bottom>
            <Img
              fluid={firstWebPhone}
              fadeIn={false}
              alt={firstWebPhoneAlt}
              className={styles.phoneLys}
            />
          </Fade>
        </div>
      </div>
    </div>
  )
}
LysAbout.propTypes = {
  logoLys: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  logoLysAlt: PropTypes.string.isRequired,
  firstDescription: PropTypes.string.isRequired,
  secondDescription: PropTypes.string.isRequired,
  firstWebPhone: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  firstWebPhoneAlt: PropTypes.string.isRequired,
}
export default LysAbout
