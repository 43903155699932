import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import { Container, Text, Heading } from '_atoms'

import styles from './styles.module.css'

const BluebenxResults = ({
  resultsTitle,
  resultsText1A,
  resultsText1B,
  resultsText1C,
  resultsText2A,
  resultsText2B,
  resultsText2C,
}) => {
  return (
    <div className={styles.resultsBlock}>
      <Container className={styles.resultsContainer}>
        <div className={styles.resultsItem}>
          <div className={styles.resultsTitle}>
            <Fade distance="10%" bottom>
              <Heading type="h2" className={styles.resultsTitle}>
                {resultsTitle}
                <span className={styles.dotSecondary}>.</span>
              </Heading>
            </Fade>
          </div>
        </div>
        <div className={styles.resultsItem}>
          <div className={styles.resultsText}>
            <Fade delay={200} distance="10%" bottom>
              <Text size="18" className={styles.resultsText}>
                {resultsText1A}
                <b>{resultsText1B}</b>
                {resultsText1C}
              </Text>
            </Fade>
          </div>
        </div>
        <div className={styles.resultsItem}>
          <div className={styles.resultsText}>
            <Fade delay={200} distance="10%" bottom>
              <Text size="18" className={styles.resultsText}>
                {resultsText2A}
                <b>{resultsText2B}</b>
                {resultsText2C}
              </Text>
            </Fade>
          </div>
        </div>
      </Container>
    </div>
  )
}

BluebenxResults.propTypes = {
  resultsTitle: PropTypes.string.isRequired,
  resultsText1A: PropTypes.string.isRequired,
  resultsText1B: PropTypes.string.isRequired,
  resultsText1C: PropTypes.string.isRequired,
  resultsText2A: PropTypes.string.isRequired,
  resultsText2B: PropTypes.string.isRequired,
  resultsText2C: PropTypes.string.isRequired,
}

export default BluebenxResults
