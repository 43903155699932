import React from 'react'
import Fade from 'react-reveal/Fade'
import { Row, Col } from 'react-simple-flex-grid'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Container, Heading, Text, BlueText, Image } from '_atoms'

import { COLORS } from '../../atoms/text'

import styles from './styles.module.css'

export const DISPLAY_ORIENTATIONS = {
  row: 'row',
  column: 'column',
}

const Services = ({ title, description, services, displayOrientation }) => {
  return displayOrientation === DISPLAY_ORIENTATIONS.column ? (
    <section className={styles.services}>
      <Container>
        <Row>
          <Col xs={12} sm={4} md={4} lg={4} className={styles.servicesText}>
            <Fade distance="20%" bottom>
              <Heading type="h4" color="default" bold className={styles.servicesTitle}>
                {title}
                <BlueText squared>.</BlueText>
              </Heading>

              <Text
                size="22"
                className={styles.servicesDescription}
                color="default"
                splitParagraphs
              >
                {description.internal.content}
              </Text>
            </Fade>
          </Col>

          <Col xs={12} sm={8} md={8} lg={8} className={styles.servicesWrapper}>
            <Row>
              {services.map((service, index) => (
                <Col
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  key={service.image.file.url}
                  className={styles.servicesService}
                >
                  <Fade distance="20%" delay={(index + 1) * 200} bottom>
                    <Image
                      src={service.image.file.url}
                      alt={service.image.description}
                      className={styles.servicesServiceImage}
                    />
                    <Text
                      size="32"
                      color={COLORS.dark}
                      bold
                      className={styles.servicesServiceTitle}
                    >
                      {service.title}
                      <BlueText squared>.</BlueText>
                    </Text>
                  </Fade>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  ) : (
    <section className={styles.services}>
      <Container>
        <Row>
          <Fade distance="20%" bottom>
            <Heading type="h4" color="default" bold className={styles.servicesTitle}>
              {title}
              <BlueText squared>.</BlueText>
            </Heading>

            <Text size="22" className={styles.servicesDescription} color="default" splitParagraphs>
              {description.internal.content}
            </Text>
          </Fade>
        </Row>
        <Row>
          <Row className={styles.servicesRowWrapper}>
            {services.map((service, index) => (
              <Col
                xs={5}
                sm={5}
                md={3}
                lg={3}
                key={service.image.file.url}
                className={classNames(styles.servicesService, styles.servicesRowService)}
              >
                <Fade distance="20%" delay={(index + 1) * 200} bottom>
                  <Image
                    src={service.image.file.url}
                    alt={service.image.description}
                    className={styles.servicesServiceImage}
                  />
                  <Text size="32" color={COLORS.dark} bold className={styles.servicesServiceTitle}>
                    {service.title}
                    <BlueText squared>.</BlueText>
                  </Text>
                </Fade>
              </Col>
            ))}
          </Row>
        </Row>
      </Container>
    </section>
  )
}
Services.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({ internal: PropTypes.shape({ content: PropTypes.string }) })
    .isRequired,
  services: PropTypes.arrayOf(Object).isRequired,
  displayOrientation: PropTypes.string,
}

Services.defaultProps = {
  displayOrientation: DISPLAY_ORIENTATIONS.column,
}

export default Services
