import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import { Image, Text, Container } from '_atoms'

import styles from './styles.module.css'

const QuiverQuantFavorite = ({
  fourthSectionCellphone1,
  fourthSectionText1,
  fourthSectionTitleText1,
  fourthSectionCellphone1Description,
}) => {
  return (
    <div className={styles.outContainer}>
      <Container>
        <div className={styles.innerContainer}>
          <div className={styles.firstSection}>
            <Fade delay={200} distance="10%" bottom>
              <div className={styles.firstLeftSide}>
                <Text className={styles.fourthSectionTitle1}>
                  {fourthSectionTitleText1}
                  <span className={styles.dot}>.</span>
                </Text>
                <Text splitParagraphs className={styles.description}>
                  {fourthSectionText1}
                </Text>
              </div>
            </Fade>
            <Fade delay={450} distance="20%" bottom>
              <div className={styles.firstRightSide}>
                <Image
                  src={fourthSectionCellphone1}
                  alt={fourthSectionCellphone1Description}
                  className={styles.fourthSectionCellphone1}
                />
              </div>
            </Fade>
          </div>
        </div>
      </Container>
    </div>
  )
}

QuiverQuantFavorite.propTypes = {
  fourthSectionCellphone1: PropTypes.string.isRequired,
  fourthSectionCellphone1Description: PropTypes.string.isRequired,
  fourthSectionText1: PropTypes.string.isRequired,
  fourthSectionTitleText1: PropTypes.string.isRequired,
}

export default QuiverQuantFavorite
