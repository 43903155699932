import React from 'react'
import Fade from 'react-reveal/Fade'
import PropTypes from 'prop-types'

import { Container, Heading, Text } from '_atoms'

import styles from './styles.module.css'

const SwfastChallengeContainer = ({ title, description, challengeList }) => {
  return (
    <Container className={styles.container}>
      <Fade distance="10%" bottom>
        <Heading type="h1" color="white" bold className={styles.heading}>
          {title}
          <span className={styles.dotPrimary}>.</span>
        </Heading>
      </Fade>
      <div className={styles.challengeContent}>
        <div className={styles.challengeDescription}>
          <div className={styles.text}>
            <Fade delay={200} distance="10%" bottom>
              <Text color="dark" size="32" splitParagraphs className={styles.paragraph}>
                {description}
              </Text>
            </Fade>
          </div>
        </div>
        <div className={styles.challengeList}>
          <ul>
            {challengeList.map(challenge => (
              <li key={challenge}>{challenge}</li>
            ))}
          </ul>
        </div>
      </div>
    </Container>
  )
}

SwfastChallengeContainer.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  challengeList: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default SwfastChallengeContainer
