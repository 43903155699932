import React from 'react'
import { Row } from 'react-simple-flex-grid'
import Fade from 'react-reveal/Fade'
import PropTypes from 'prop-types'

import { Text, ScrollArrow, Image } from '_atoms'
import { NavLink } from '_molecules'
import AppStoreBadge from '_images/svgs/badge-app-store.svg'
import PlayStoreBadge from '_images/svgs/badge-play-store.svg'

import styles from './styles.module.css'

const MoveHero = ({
  logo,
  logoDescription,
  heroDescription,
  heroVectary,
  heroVectaryDescription,
  heroBackgroundTexture,
}) => {
  return (
    <div className={styles.content} style={{ backgroundImage: `url(${heroBackgroundTexture})` }}>
      <div className={styles.contentLeft}>
        <Fade delay={200} distance="20%" bottom>
          <div className={styles.logo}>
            <Image src={logo} alt={logoDescription} />
          </div>
        </Fade>
        <Fade delay={250} distance="20%" bottom>
          <Text color="white" size="24" splitParagraphs className={styles.heroDescription}>
            {heroDescription}
          </Text>
        </Fade>
        <Fade delay={300} distance="20%" bottom>
          <Row className={styles.contentAppStores}>
            <NavLink hasPrefix={false} to="https://apps.apple.com/br/app/move-driver/id1548759148">
              <AppStoreBadge />
            </NavLink>
            <NavLink
              hasPrefix={false}
              to="https://play.google.com/store/apps/details?id=com.movewhitelabel&hl=pt_BR&gl=US"
            >
              <PlayStoreBadge />
            </NavLink>
          </Row>
        </Fade>
        <Row className={styles.heroArrowDown}>
          <Fade distance="20%" delay={400} bottom>
            <ScrollArrow className={styles.heroArrowButton} isButton scrollId="challenge" />
          </Fade>
        </Row>
      </div>
      <div className={styles.contentRight}>
        <Fade distance="25%" delay={400} bottom>
          <Image src={heroVectary} alt={heroVectaryDescription} className={styles.heroVectary} />
        </Fade>
      </div>
    </div>
  )
}
MoveHero.propTypes = {
  logo: PropTypes.string.isRequired,
  heroBackgroundTexture: PropTypes.string.isRequired,
  logoDescription: PropTypes.string.isRequired,
  heroDescription: PropTypes.string.isRequired,
  heroVectary: PropTypes.string.isRequired,
  heroVectaryDescription: PropTypes.string.isRequired,
}
export default MoveHero
