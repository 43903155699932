import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'

import { Text, Heading } from '_atoms'

import styles from './styles.module.css'

const LysAdmin = ({ adminTitle, adminDescription, adminIllustration, adminIllustrationAlt }) => {
  return (
    <div className={styles.backgroundDiagonal}>
      <div className={styles.containerDescription}>
        <Fade delay={200} distance="10%" bottom>
          <Heading type="h1" className={styles.title}>
            {adminTitle}
            <span className={styles.dot}>.</span>
          </Heading>

          <Text className={styles.description}>{adminDescription}</Text>
        </Fade>
      </div>
      <div className={styles.containerIllustration}>
        <Fade delay={450} distance="20%" bottom>
          <Img
            fluid={adminIllustration}
            fadeIn={false}
            alt={adminIllustrationAlt}
            className={styles.adminIllustration}
          />
        </Fade>
      </div>
    </div>
  )
}
LysAdmin.propTypes = {
  adminTitle: PropTypes.string.isRequired,
  adminIllustrationAlt: PropTypes.string.isRequired,
  adminDescription: PropTypes.string.isRequired,
  adminIllustration: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
}
export default LysAdmin
