import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Heading, Image, Text } from '_atoms'

import styles from './styles.module.css'

const MOBILE_BREAK = 768

const ValueCard = ({ title, description, icon, position, side, clickEventPath }) => {
  const [afterHover, setAfterHover] = useState(false)
  const [clickedMobile, setClickedMobile] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [card, setCard] = useState(null)

  useEffect(() => {
    if (!isMobile) return

    // mobile only to replace :hover transition
    // check if container click happens inside of one of the cards and closes other cards
    // if transitions are running backwards on dev hot reload updates, refresh may solve it
    if (clickEventPath.length > 0 && card) {
      const clickedInSelectedCard = clickEventPath.find(current => current === card)

      if (clickedInSelectedCard) {
        setClickedMobile(oldState => {
          setAfterHover(oldState)
          return !oldState
        })
      } else {
        setClickedMobile(false)
        setAfterHover(true)
      }
    }
  }, [card, clickEventPath, isMobile])

  useEffect(() => {
    setIsMobile(window.innerWidth <= MOBILE_BREAK)
  }, [])

  return (
    <div
      ref={setCard}
      className={classnames(styles.container, styles[position], styles[side], {
        [styles.afterHover]: afterHover,
        [styles.clickedMobile]: !afterHover && clickedMobile && isMobile,
      })}
      onMouseLeave={() => {
        if (!isMobile) setAfterHover(true)
      }}
      onMouseEnter={() => {
        if (!isMobile) setAfterHover(false)
      }}
    >
      <div className={styles.titleAndIcon}>
        <Image src={icon} alt={title} className={styles.icon} />
        <Heading type="h3" className={styles.text} bold>
          {title}
        </Heading>
      </div>
      <Text className={styles.description}>{description}</Text>
    </div>
  )
}

ValueCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  side: PropTypes.string.isRequired,
  clickEventPath: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
}

ValueCard.defaultProps = {
  clickEventPath: [],
}

export default ValueCard
