import React from 'react'
import Fade from 'react-reveal/Fade'
import PropTypes from 'prop-types'

import { Container, Heading, Text } from '_atoms'

import styles from './styles.module.css'

const SkyroamChallengeContainer = ({ title, description }) => {
  return (
    <Container>
      <div className={styles.challengeContent}>
        <Fade distance="10%" bottom>
          <Heading type="h1" color="skyroamSecondary" bold className={styles.heading}>
            {title}
            <span className={styles.dotPrimary}>.</span>
          </Heading>
        </Fade>

        <div className={styles.text}>
          <Fade delay={200} distance="10%" bottom>
            <Text color="dark" size="32" splitParagraphs className={styles.paragraph}>
              {description}
            </Text>
          </Fade>
        </div>
      </div>
    </Container>
  )
}

SkyroamChallengeContainer.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default SkyroamChallengeContainer
