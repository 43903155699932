import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import { Container, Text, Image, Heading } from '_atoms'

import styles from './styles.module.css'

const DifferentialSection = ({
  differentialTitle,
  differentialDescription,
  differentialCardCode,
  differentialCardCodeAlt,
}) => {
  return (
    <Container>
      <div className={styles.content}>
        <div className={styles.contentLeft}>
          <Fade distance="20%" bottom>
            <Heading type="h4" bold className={styles.differentialTitle}>
              {differentialTitle}
              <Text className={styles.dot}>.</Text>
            </Heading>
            <Text size="22" splitParagraphs className={styles.differentialDescription}>
              {differentialDescription}
            </Text>
          </Fade>
        </div>

        <div className={styles.contentRight}>
          <Fade>
            <Image src={differentialCardCode} alt={differentialCardCodeAlt} />
          </Fade>
        </div>
      </div>
    </Container>
  )
}
DifferentialSection.propTypes = {
  differentialTitle: PropTypes.string.isRequired,
  differentialDescription: PropTypes.string.isRequired,
  differentialCardCode: PropTypes.string.isRequired,
  differentialCardCodeAlt: PropTypes.string.isRequired,
}

export default DifferentialSection
