import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import { Image, Text } from '_atoms'

import styles from './styles.module.css'

const AboutApplicationCresol = ({
  phoneOneSectionFour,
  titleOneSectionFour,
  textOneSectionFour,
  phoneTwoSectionFour,
  titleTwoSectionFour,
  textTwoSectionFour,
  phoneThreeSectionFour,
  titleThreeSectionFour,
  textThreeSectionFour,
  phoneOneSectionFourDescription,
  phoneTwoSectionFourDescription,
  phoneThreeSectionFourDescription,
}) => {
  return (
    <div className={styles.containerGlobal}>
      <div className={styles.background}>
        <section className={styles.phoneTextQR}>
          <Fade delay={200} distance="10%" bottom>
            <Image
              src={phoneOneSectionFour}
              className={styles.sizeImage}
              alt={phoneOneSectionFourDescription}
            />
          </Fade>
          <Fade delay={200} distance="10%" bottom>
            <div className={styles.containerWithTextsQR}>
              <Text size="48" className={styles.title}>
                {titleOneSectionFour}
                <span className={styles.dotSecondary}>.</span>
              </Text>
              <Text size="24" className={styles.texts}>
                {textOneSectionFour}
              </Text>
            </div>
          </Fade>
        </section>
        <section className={styles.phoneTextAccount}>
          <Fade delay={200} distance="10%" bottom>
            <div className={styles.containerWithTextsAccount}>
              <Text size="48" className={styles.title}>
                {titleTwoSectionFour}
                <span className={styles.dotSecondary}>.</span>
              </Text>
              <Text size="24" className={styles.texts}>
                {textTwoSectionFour}
              </Text>
            </div>
          </Fade>
          <Fade delay={200} distance="10%" bottom>
            <Image
              src={phoneTwoSectionFour}
              className={styles.sizeImage}
              alt={phoneTwoSectionFourDescription}
            />
          </Fade>
        </section>
        <section className={styles.phoneTextQuick}>
          <Fade delay={200} distance="10%" bottom>
            <Image
              src={phoneThreeSectionFour}
              className={styles.sizeImage}
              alt={phoneThreeSectionFourDescription}
            />
          </Fade>
          <Fade delay={200} distance="10%" bottom>
            <div className={styles.containerWithTextsQuick}>
              <Text size="48" className={styles.title}>
                {titleThreeSectionFour}
                <span className={styles.dotSecondary}>.</span>
              </Text>
              <Text size="24" className={styles.texts}>
                {textThreeSectionFour}
              </Text>
            </div>
          </Fade>
        </section>
      </div>
    </div>
  )
}

AboutApplicationCresol.propTypes = {
  phoneOneSectionFour: PropTypes.string.isRequired,
  titleOneSectionFour: PropTypes.string.isRequired,
  textOneSectionFour: PropTypes.string.isRequired,
  phoneTwoSectionFour: PropTypes.string.isRequired,
  titleTwoSectionFour: PropTypes.string.isRequired,
  textTwoSectionFour: PropTypes.string.isRequired,
  phoneThreeSectionFour: PropTypes.string.isRequired,
  titleThreeSectionFour: PropTypes.string.isRequired,
  textThreeSectionFour: PropTypes.string.isRequired,
  phoneOneSectionFourDescription: PropTypes.string.isRequired,
  phoneTwoSectionFourDescription: PropTypes.string.isRequired,
  phoneThreeSectionFourDescription: PropTypes.string.isRequired,
}

export default AboutApplicationCresol
