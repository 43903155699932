import React from 'react'
import Fade from 'react-reveal/Fade'

import { Image } from '_atoms'
import challengeBg from '_images/pages/portfolio/skyroam/challenge-bg.png'

import styles from './styles.module.css'

const SkyroamChallenge = () => {
  return (
    <div className={styles.challengeFixed}>
      <Fade delay={350} distance="20%" bottom>
        <Image src={challengeBg} alt="Challenge" />
      </Fade>
    </div>
  )
}

export default SkyroamChallenge
