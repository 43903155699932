import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { SliderNav } from '_atoms'

import TinySlider from '../tiny-slider'

import styles from './styles.module.css'

const DEFAULT_SLIDER_OPTIONS = {
  controls: false,
  mouseDrag: true,
  loop: true,
  autoHeight: true,
  autoplay: true,
  autoplayHoverPause: true,
  autoplayTimeout: 30000,
  responsive: {
    1025: {
      autoHeight: false,
    },
  },
}

const ImageSlider = ({ id, children, className }) => {
  let icons = []

  if (children.length > 0) icons = children.map((_, ind) => <SliderNav key={`key-${ind}`} />) // eslint-disable-line react/no-array-index-key

  return (
    <div className={styles.overflow}>
      <div className={classNames(styles.container, 'tns-img')}>
        <TinySlider
          id={id}
          className={className}
          options={{ ...DEFAULT_SLIDER_OPTIONS, navContainer: `#${id}-nav` }}
        >
          {children}
        </TinySlider>

        <div id={`${id}-nav`} className={styles.nav}>
          {icons}
        </div>
      </div>
    </div>
  )
}

ImageSlider.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

ImageSlider.defaultProps = {
  className: undefined,
}

export default ImageSlider
