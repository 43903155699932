import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import { Image, Text, Heading } from '_atoms'

import styles from './styles.module.css'

const AboutCresol = ({
  titleOneSectionTwo,
  textOneSectionTwoCresol,
  largerCircleCresol,
  screenPhonesCresol,
  titleTwoSectionTwo,
  textTwoSectionTwoCresol,
  textThreeSectionTwoCresol,
  titleThreeSectionTwo,
  textFourSectionTwoCresol,
  screenPhonesCresolDescription,
  largerCircleCresolDescription,
}) => {
  return (
    <div className={styles.containerSectionTwo}>
      <div className={styles.containerContent}>
        <section className={styles.containerGlobalOne}>
          <Fade delay={200} distance="10%" bottom>
            <div className={styles.containerOne}>
              <Heading type="h1" size="48" className={styles.titleOne}>
                {titleOneSectionTwo}
                <span className={styles.dotSecondary}>.</span>
              </Heading>
              <Text size="22" className={styles.textTwo}>
                {textOneSectionTwoCresol}
              </Text>
            </div>
          </Fade>
          <Fade delay={200} distance="10%" bottom>
            <div className={styles.containerImageSectionTwo}>
              <Image src={largerCircleCresol} alt={screenPhonesCresolDescription} />
            </div>
          </Fade>
        </section>
        <section className={styles.containerGlobalTwo}>
          <div className={styles.containerTwo}>
            <Fade delay={200} distance="10%" bottom>
              <div className={styles.containerImageTwo}>
                <Image src={screenPhonesCresol} alt={largerCircleCresolDescription} />
              </div>
            </Fade>
            <Fade delay={200} distance="10%" bottom>
              <div className={styles.containerTextTwo}>
                <Text size="48" className={styles.titleTwo}>
                  {titleTwoSectionTwo}
                  <span className={styles.dotSecondary}>.</span>
                </Text>

                <Text size="22" className={styles.textTwo}>
                  {textTwoSectionTwoCresol}
                </Text>
                <Text size="22" className={styles.textTwo}>
                  {textThreeSectionTwoCresol}
                </Text>
              </div>
            </Fade>
          </div>
        </section>
        <section className={styles.containerGlobalThree}>
          <Fade delay={200} distance="10%" bottom>
            <div className={styles.containerThree}>
              <Text size="48" className={styles.titleThree}>
                {titleThreeSectionTwo}
                <span className={styles.dotSecondary}>.</span>
              </Text>
              <Text size="22" className={styles.textThree}>
                {textFourSectionTwoCresol}
              </Text>
            </div>
          </Fade>
        </section>
      </div>
    </div>
  )
}

AboutCresol.propTypes = {
  titleOneSectionTwo: PropTypes.string.isRequired,
  textOneSectionTwoCresol: PropTypes.string.isRequired,
  largerCircleCresol: PropTypes.string.isRequired,
  screenPhonesCresol: PropTypes.string.isRequired,
  titleTwoSectionTwo: PropTypes.string.isRequired,
  textTwoSectionTwoCresol: PropTypes.string.isRequired,
  textThreeSectionTwoCresol: PropTypes.string.isRequired,
  titleThreeSectionTwo: PropTypes.string.isRequired,
  textFourSectionTwoCresol: PropTypes.string.isRequired,
  screenPhonesCresolDescription: PropTypes.string.isRequired,
  largerCircleCresolDescription: PropTypes.string.isRequired,
}

export default AboutCresol
