import React from 'react'
import PropTypes from 'prop-types'
import { Row } from 'react-simple-flex-grid'
import StackGrid from 'react-stack-grid'

import { Container, Heading, Text, Image } from '_atoms'
import { useWindowSize } from '_hooks'

import styles from './styles.module.css'

const AndroidDevelopmentIndustries = ({ industries, industriesTitle, industriesDescription }) => {
  const [screenWidth] = useWindowSize()
  const isMobileView = screenWidth < 768

  return (
    <section className={styles.industriesSection}>
      <Container>
        <Row className={styles.industriesSectionHeader} justify="center" align="middle">
          <Heading type="h2" bold className={styles.industriesSectionTitle}>
            {industriesTitle}
          </Heading>
          <Text size="16" className={styles.industriesSectionDescription}>
            {industriesDescription}
          </Text>
        </Row>

        <StackGrid columnWidth={isMobileView ? '100%' : '33.33%'}>
          {industries.map((industry, index) => (
            <Row index={index} className={styles.industry}>
              <Image src={industry.image.file.url} />
              <Text size="16" className={styles.industryTitle}>
                {industry.imageTitle}
              </Text>
              <Text size="16" className={styles.industryDescription}>
                {industry.imageDescription.imageDescription}
              </Text>
            </Row>
          ))}
        </StackGrid>
      </Container>
    </section>
  )
}

AndroidDevelopmentIndustries.propTypes = {
  industriesTitle: PropTypes.string.isRequired,
  industriesDescription: PropTypes.string.isRequired,
  industries: PropTypes.arrayOf({
    image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    imageTitle: PropTypes.string,
    imageDescription: PropTypes.shape({
      imageDescription: PropTypes.string,
    }),
  }).isRequired,
}

export default AndroidDevelopmentIndustries
