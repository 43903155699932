import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link, IntlContextConsumer } from 'gatsby-plugin-intl'

import BlueRectangle from '_images/svgs/blue-rectangle.svg'

import styles from './styles.module.css'

const NavLink = ({ className, children, to, isProxied, isOutline, hasPrefix }) => {
  // Gatsby says we should not use the Link component for external links.
  // Internal links have a dash and a string name.
  // Proxied links look like internal paths, but they actually load content
  // from an external source.
  const internal = /^\/(?!\/)/.test(to) && !isProxied
  return internal ? (
    <Link
      className={classNames(className, styles.navlink, { [styles.outline]: isOutline })}
      // to={`${urlLocale}${to}`}
      to={to}
      activeClassName={styles.activeLink}
    >
      <span>{children}</span>
      <BlueRectangle />
    </Link>
  ) : (
    <IntlContextConsumer>
      {({ languageToPrefix, defaultLanguage, language }) => {
        const prefix =
          hasPrefix && language !== defaultLanguage ? `/${languageToPrefix[language]}` : ''
        return (
          <a
            className={classNames(className, styles.navlink, { [styles.outline]: isOutline })}
            href={`${prefix}${to}`}
          >
            <span>{children}</span>
            <BlueRectangle />
          </a>
        )
      }}
    </IntlContextConsumer>
  )
}

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isProxied: PropTypes.bool,
  isOutline: PropTypes.bool,
  hasPrefix: PropTypes.bool,
}

NavLink.defaultProps = {
  className: '',
  isProxied: false,
  isOutline: false,
  hasPrefix: false,
}

export default NavLink
