import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import variables from '_config/css-variables'
import { Image, Container, Text, Heading } from '_atoms'

import style from './styles.module.css'

const BluebenxWallet = ({
  walletTitle,
  walletDescription,
  walletMobileScreen,
  walletMobileScreenAlt,
}) => {
  return (
    <Container className={style.walletContainer}>
      <div className={style.walletInfo}>
        <div className={style.walletImage}>
          <Fade delay={450} distance="20%" bottom>
            <Image
              src={walletMobileScreen}
              className={style.walletMobileScreen}
              alt={walletMobileScreenAlt}
            />
          </Fade>
        </div>

        <div className={style.walletContent}>
          <div className={style.walletDescriptionWrapper}>
            <Fade delay={200} distance="10%" bottom>
              <Heading type="h2" className={style.walletTitle}>
                {walletTitle}
                <span className={style.dotSecondary}>.</span>
              </Heading>
              <Text
                color={variables['color-white']}
                size="22"
                splitParagraphs
                className={style.walletDescription}
              >
                {walletDescription}
              </Text>
            </Fade>
          </div>
        </div>
      </div>
    </Container>
  )
}

BluebenxWallet.propTypes = {
  walletTitle: PropTypes.string.isRequired,
  walletDescription: PropTypes.string.isRequired,
  walletMobileScreen: PropTypes.string.isRequired,
  walletMobileScreenAlt: PropTypes.string.isRequired,
}

export default BluebenxWallet
