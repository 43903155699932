import React from 'react'
import Fade from 'react-reveal/Fade'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'

import { Image } from '_atoms'

import styles from './styles.module.css'

const EchoMainImages = ({ heroImages, shouldAnimate }) => {
  return (
    <>
      <Fade delay={150} opposite when={shouldAnimate} distance="20%" bottom>
        <div className={styles.introductionPersonsContainer}>
          <Img
            fluid={heroImages.man.image}
            alt={heroImages.man.alt}
            objectFit="contain"
            imgStyle={{ objectFit: 'contain', objectPosition: 'bottom right' }}
            fadeIn={false}
          />
          <Img
            fluid={heroImages.woman.image}
            alt={heroImages.woman.alt}
            objectFit="contain"
            imgStyle={{ objectFit: 'contain', objectPosition: 'bottom left' }}
            fadeIn={false}
          />
        </div>
      </Fade>

      <Fade delay={150} opposite when={shouldAnimate} distance="20%" bottom>
        <div className={styles.introductionPhoneContainer}>
          <Image src={heroImages.phone.image} alt={heroImages.phone.alt} />
          <div />
        </div>
      </Fade>
      <Fade delay={350} opposite when={shouldAnimate} distance="20%" bottom>
        <div className={styles.introductionCartContainer}>
          <Image src={heroImages.cart.image} alt={heroImages.cart.alt} />
        </div>
      </Fade>
    </>
  )
}

EchoMainImages.propTypes = {
  shouldAnimate: PropTypes.bool.isRequired,
  heroImages: PropTypes.shape({
    phone: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    woman: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    man: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    cart: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
  }).isRequired,
}

export default EchoMainImages
