import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'

import { Text } from '_atoms'

import styles from './styles.module.css'

const RaimanaOpportunity = ({ opportunityDescription, opportunityImage }) => {
  return (
    <div className={styles.container}>
      <div className={styles.containerImage}>
        <Fade delay={200} distance="10%" bottom>
          <Img fluid={opportunityImage} fadeIn={false} />
        </Fade>
      </div>
      <div className={styles.containerDescription}>
        <Fade delay={450} distance="20%" bottom>
          <Text splitParagraphs>{opportunityDescription}</Text>
        </Fade>
      </div>
    </div>
  )
}

RaimanaOpportunity.propTypes = {
  opportunityImage: PropTypes.shape({
    description: PropTypes.string,
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      sizes: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcSetWebp: PropTypes.string,
      srcWebp: PropTypes.string,
    }),
  }).isRequired,
  opportunityDescription: PropTypes.string.isRequired,
}
export default RaimanaOpportunity
