import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'
import Fade from 'react-reveal/Fade'

import SkyroamLogo from '_images/svgs/skyroam-logo.inline.svg'
import { Container, ScrollArrow, Text } from '_atoms'

import styles from './styles.module.css'

const SkyroamContainer = ({ description }) => {
  return (
    <Container>
      <Row>
        <Col xs={12} sm={12} md={5} lg={5}>
          <div className={styles.heroContent}>
            <Fade distance="10%" bottom>
              <SkyroamLogo className={styles.heroLogo} />
            </Fade>

            <div className={styles.heroText}>
              <Fade delay={200} distance="10%" bottom>
                <Text color="white" size="32" splitParagraphs className={styles.heroParagraph}>
                  {description}
                </Text>
              </Fade>
            </div>
            <ScrollArrow className={styles.heroArrow} />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

SkyroamContainer.propTypes = {
  description: PropTypes.string.isRequired,
}

export default SkyroamContainer
