import React from 'react'
import Fade from 'react-reveal/Fade'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import { Container, Heading, Text, BlueText, Button } from '_atoms'
import { CompanyStats } from '_molecules'

import styles from './styles.module.css'

const AboutUs = ({ title, description, image, buttonText, onClick, id, buttonDescription }) => (
  <section className={styles.aboutUs}>
    <Container>
      <Fade distance="20%" bottom>
        <Heading type="h4" color="default" bold className={styles.aboutUsTitle}>
          {title}
          <BlueText squared>.</BlueText>
        </Heading>
      </Fade>

      <Fade distance="20%" delay={200} bottom>
        <Text size="22" color="dark" splitParagraphs className={styles.aboutUsDescription}>
          {description.internal.content}
        </Text>
      </Fade>
    </Container>

    <Fade distance="10%" bottom>
      <Img fluid={image.fluid} className={styles.aboutUsImage} />
    </Fade>

    <CompanyStats className={styles.aboutUsCompanyStats} />
    <Fade distance="20%" bottom>
      <Heading type="h4" color="default" bold className={styles.aboutUsButtonDescription}>
        {buttonDescription}
        <BlueText squared>?</BlueText>
      </Heading>
    </Fade>

    <Fade distance="20%" delay={200} bottom>
      <Button type="primary" className={styles.aboutUsButton} onClick={onClick} id={id}>
        {buttonText}
      </Button>
    </Fade>
  </section>
)

AboutUs.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({ internal: PropTypes.shape({ content: PropTypes.string }) })
    .isRequired,
  image: PropTypes.shape({ fluid: PropTypes.shape({}) }).isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  buttonDescription: PropTypes.string,
}

AboutUs.defaultProps = {
  buttonDescription: 'Still need convincing',
}

export default AboutUs
