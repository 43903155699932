import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'

import { Text, ScrollArrow, Heading } from '_atoms'

import styles from './styles.module.css'

const RaimanaAbout = ({ aboutDescription, aboutImage }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.containerDescription}>
          <Fade delay={200} distance="10%" bottom>
            <Heading type="h1" className={styles.title}>
              <Text splitParagraphs>{aboutDescription}</Text>
            </Heading>
            <ScrollArrow
              scrollId="opportunity"
              isButton
              scrollOffset={70}
              className={styles.arrow}
            />
          </Fade>
        </div>

        <div className={styles.containerImage}>
          <Fade delay={450} distance="20%" bottom>
            <Img fluid={aboutImage} fadeIn={false} />
          </Fade>
        </div>
      </div>
    </div>
  )
}

RaimanaAbout.propTypes = {
  aboutDescription: PropTypes.string.isRequired,
  aboutImage: PropTypes.shape({
    description: PropTypes.string,
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      sizes: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcSetWebp: PropTypes.string,
      srcWebp: PropTypes.string,
    }),
  }).isRequired,
}
export default RaimanaAbout
