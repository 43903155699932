import React from 'react'
import PropTypes from 'prop-types'

import ArrowIcon from '_images/svgs/icon-arrow-right.svg'

import styles from './styles.module.css'

const JobCard = ({ title, city, state, href }) => {
  const location = `${city}${state ? `, ${state}` : ''}`

  return (
    <a className={styles.link} href={href}>
      <p className={styles.jobCity}>{location}</p>
      <div className={styles.jobTitleWrapper}>
        <h3>{title}</h3>
        <ArrowIcon />
      </div>
    </a>
  )
}

JobCard.propTypes = {
  title: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
}

export default JobCard
