import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'
import Fade from 'react-reveal/Fade'

import { Container, Heading, Text, Image } from '_atoms'

import styles from './styles.module.css'

const SkyroamMarketing = ({ title, description, image, alt }) => {
  return (
    <Container className={styles.marketingContainer}>
      <Row className={styles.marketingRow}>
        <Col xs={12} sm={12} md={8} lg={8} className={styles.marketingImage}>
          <Fade delay={450} distance="20%" bottom>
            <Image {...image} alt={alt} />
          </Fade>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4}>
          <div className={styles.marketingContent}>
            <Fade distance="10%" bottom>
              <Heading type="h4" color="skyroamSecondary" bold className={styles.heading}>
                {title}
                <span className={styles.dotPrimary}>.</span>
              </Heading>
            </Fade>

            <div className={styles.marketingText}>
              <Fade delay={200} distance="10%" bottom>
                <Text size="32" splitParagraphs className={styles.paragraph}>
                  {description}
                </Text>
              </Fade>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

SkyroamMarketing.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  alt: PropTypes.string.isRequired,
}

export default SkyroamMarketing
