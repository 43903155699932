import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles.module.css'

const defaultIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#fff"
    strokeWidth="4"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="20 6 9 17 4 12" />
  </svg>
)

const Checkbox = ({
  name,
  label,
  checked,
  onChange,
  setFieldValue,
  className,
  checkIcon,
  ...props
}) => {
  const handleSetFieldValue = e => {
    const checkState = e.currentTarget.checked

    return setFieldValue(name, checkState)
  }

  const handleChange = e => {
    if (setFieldValue) {
      return handleSetFieldValue(e)
    }

    return onChange(e)
  }

  const checkboxProps = {
    className: classNames(styles.checkboxInput, className),
    name,
    type: 'checkbox',
    checked,
    onChange: handleChange,
    ...props,
  }

  return (
    <label className={styles.checkboxLabel} htmlFor={name}>
      <input id={name} aria-label={label} {...checkboxProps} />

      <div className={classNames(styles.checkmark)}>
        <span className={styles.checkmarkIcon}>{checkIcon || defaultIcon}</span>
      </div>
      {label}
    </label>
  )
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  checked: PropTypes.bool,
  checkIcon: PropTypes.node,
  className: PropTypes.string,
}

Checkbox.defaultProps = {
  checked: false,
  className: undefined,
  checkIcon: '',
  onChange: () => {},
  setFieldValue: () => {},
}

export default Checkbox
