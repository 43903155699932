import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'

import { Container, Text, Heading } from '_atoms'

import styles from './styles.module.css'

const ThawOpportunity = ({ title, description, image }) => {
  return (
    <Container className={styles.opportunityContainer}>
      <div className={styles.opportunityBG}>
        <div className={styles.opportunityContentWrapper}>
          <div className={styles.opportunityContent}>
            <Fade distance="10%" bottom>
              <Heading type="h1" bold className={styles.opportunityTitle}>
                {title}
                <span className={styles.dotSecondary}>.</span>
              </Heading>
            </Fade>
            <div className={styles.opportunityDescriptionWrapper}>
              <Fade delay={200} distance="10%" bottom>
                <Text size="22" splitParagraphs className={styles.opportunityDescription}>
                  {description}
                </Text>
              </Fade>
            </div>
          </div>
          <div className={styles.opportunityImage}>
            <Fade delay={450} distance="20%" bottom>
              <Img
                fluid={image}
                imgStyle={{
                  objectFit: 'contain',
                  position: 'relative',
                }}
                fadeIn={false}
              />
            </Fade>
          </div>
        </div>
      </div>
    </Container>
  )
}

ThawOpportunity.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
}

export default ThawOpportunity
