import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import { Row, Col } from 'react-simple-flex-grid'

import { Container, Heading, Image, Text } from '_atoms'
import platformBg from '_images/pages/portfolio/skyroam/platform-bg.png'

import styles from './styles.module.css'

const SkyroamPlatform = ({ title, description, image, alt }) => {
  return (
    <Container className={styles.platformContainer}>
      <Row>
        <Col xs={12} sm={12} md={5} lg={5}>
          <div className={styles.platformContent}>
            <Fade distance="10%" bottom>
              <Heading type="h4" color="white" bold className={styles.heading}>
                {title}
                <span className={styles.dotPrimary}>.</span>
              </Heading>
            </Fade>

            <div className={styles.text}>
              <Fade delay={200} distance="10%" bottom>
                <Text color="white" size="32" splitParagraphs className={styles.paragraph}>
                  {description}
                </Text>
              </Fade>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={12} md={7} lg={7} className={styles.platformImages}>
          <div className={styles.platformBg}>
            <Fade delay={350} distance="20%" bottom>
              <Image src={platformBg} alt="Platform" />
            </Fade>
          </div>

          <div className={styles.platformImage}>
            <Fade delay={450} distance="20%" bottom>
              <Image src={image.src} srcSet={image.srcSet} alt={alt} />
            </Fade>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

SkyroamPlatform.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  alt: PropTypes.string.isRequired,
}

export default SkyroamPlatform
