import React from 'react'

import { Text } from '_atoms'

import styles from './styles.module.css'

const FooterCompact = () => {
  const currentYear = new Date().getFullYear()
  return (
    <div className={styles.footerCompact}>
      <Text size="14" color="white">
        © {currentYear} Cheesecake Labs, Inc.
      </Text>
    </div>
  )
}

export default FooterCompact
