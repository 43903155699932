import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'
import Fade from 'react-reveal/Fade'
import classNames from 'classnames'

import integrationBg from '_images/pages/portfolio/skyroam/integration-bg.svg'
import integrationOverlay from '_images/pages/portfolio/skyroam/integration-overlay.png'
import { Container, Heading, Text, Image } from '_atoms'

import styles from './styles.module.css'

const SkyroamIntegration = ({ title, description, image, alt }) => {
  return (
    <Container className={styles.integrationContainer}>
      <Row>
        <Col xs={12} sm={12} md={5} lg={5}>
          <div className={styles.integrationContent}>
            <Fade distance="10%" bottom>
              <Heading type="h4" color="white" bold className={styles.heading}>
                {title}
                <span className={styles.dotPrimary}>.</span>
              </Heading>
            </Fade>

            <div className={styles.integrationText}>
              <Fade delay={200} distance="10%" bottom>
                <Text color="white" size="32" splitParagraphs className={styles.paragraph}>
                  {description}
                </Text>
              </Fade>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={12} md={7} lg={7} className={styles.integrationImages}>
          <div className={styles.integrationBg}>
            <Fade delay={350} distance="20%" bottom>
              <Image src={integrationBg} alt={title} />
            </Fade>
          </div>

          <div className={styles.integrationImage}>
            <Fade delay={450} distance="20%" bottom>
              <Image {...image} alt={alt} />
            </Fade>
          </div>

          <div className={classNames(styles.integrationBg, styles.integrationOverlay)}>
            <Fade delay={350} distance="20%" bottom>
              <Image src={integrationOverlay} alt={title} />
            </Fade>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

SkyroamIntegration.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  alt: PropTypes.string.isRequired,
}

export default SkyroamIntegration
