import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'

import { Container, Heading, Text, Image } from '_atoms'
import plusIcon from '_images/pages/services/icon-plus.svg'
import minusIcon from '_images/pages/services/icon-minus.svg'

import styles from './styles.module.css'

const AndroidDevelopmentFaq = ({ faq, faqTitle, faqImage }) => {
  const [toggle, setToggle] = useState([])

  const questions = []

  useEffect(() => {
    faq.forEach(() => {
      questions.push(false)
    })

    setToggle(questions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleQuestion = index => {
    questions[index] = !toggle[index]

    setToggle(questions)
  }

  return (
    <section className={styles.faqSection}>
      <Container>
        <Row>
          <Heading type="h2" bold className={styles.faqSectionTitle}>
            {faqTitle}
          </Heading>
        </Row>

        <Row gutter={32} align="middle">
          <Col xs={12} sm={8} md={8} lg={8}>
            {faq.map((item, i) => {
              return (
                <div
                  role="button"
                  tabIndex={0}
                  key={item.question}
                  onClick={() => toggleQuestion(i)}
                  onKeyDown={() => toggleQuestion(i)}
                  className={styles.faqSectionItem}
                >
                  <Row className={styles.faqSectionQuestion} justify="space-between" align="middle">
                    <Col span={11}>
                      <Text size="16" color="dark">
                        {item.question}
                      </Text>
                    </Col>
                    <Col span={1} className={styles.faqSectionIcon}>
                      <Image src={toggle[i] ? minusIcon : plusIcon} />
                    </Col>
                  </Row>

                  <div
                    className={
                      toggle[i] ? styles.faqSectionAnswerOpen : styles.faqSectionAnswerClose
                    }
                  >
                    <Text size="14">{item.answer}</Text>
                  </div>
                </div>
              )
            })}
          </Col>
          <Col xs={12} sm={4} md={4} lg={4}>
            <Image src={faqImage.file.url} />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

AndroidDevelopmentFaq.propTypes = {
  faq: PropTypes.arrayOf({
    question: PropTypes.string,
    answer: PropTypes.string,
  }).isRequired,
  faqTitle: PropTypes.string.isRequired,
  faqImage: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
}

export default AndroidDevelopmentFaq
