import React from 'react'
import { Row, Col } from 'react-simple-flex-grid'
import PropTypes from 'prop-types'

import { Container, Heading } from '_atoms'

import TextJSON from '../../../text-json'

import styles from './styles.module.css'

const Discover2Container = ({
  discoverTitleFirstPart,
  discoverTitleLastChar,
  discoverDescription,
}) => {
  return (
    <Container>
      <Row>
        <Col xs={12} sm={12} md={5} lg={5}>
          <div className={styles.discoverContent}>
            <Heading className={styles.discoverTitle} type="h4" color="white" bold>
              {discoverTitleFirstPart}
              <span className={styles.discoverTitleLastChar}>{discoverTitleLastChar}</span>
            </Heading>
            <TextJSON color="dark" size="22" className={styles.discoverParagraph}>
              {discoverDescription}
            </TextJSON>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

Discover2Container.propTypes = {
  discoverTitleLastChar: PropTypes.string.isRequired,
  discoverDescription: PropTypes.shape({}).isRequired,
  discoverTitleFirstPart: PropTypes.string.isRequired,
}
export default Discover2Container
