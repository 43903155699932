import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import { Image, Text, ScrollArrow } from '_atoms'

import styles from './styles.module.css'

const HeroSectionCresol = ({
  logoCresol,
  logoCresolDescription,
  textOneSectionOneCresol,
  textTwoSectionOneCresol,
  handAndCellphone,
  handAndCellphoneDescription,
  backgroundSectionOneCresol,
}) => {
  return (
    <div
      className={styles.heroSectionCresol}
      style={{ backgroundImage: `url(${backgroundSectionOneCresol})` }}
    >
      <div className={styles.containerContent}>
        <Fade delay={200} distance="10%" bottom>
          <div className={styles.containerText}>
            <div className={styles.containerLogoCresol}>
              <Image src={logoCresol} className={styles.logo} alt={logoCresolDescription} />
            </div>

            <div className={styles.containerTextSectionOne}>
              <Text size="32" className={styles.textSectionOneCresol}>
                {textOneSectionOneCresol}
              </Text>
              <Text size="32" className={styles.textSectionOneCresol}>
                {textTwoSectionOneCresol}
              </Text>
            </div>
            <ScrollArrow
              isButton
              scrollId="challenge-cresol"
              scrollOffset={70}
              className={styles.ArrowCresol}
            />
          </div>
        </Fade>
      </div>
      <Fade delay={100} distance="10%" bottom>
        <div className={styles.containerCellphone}>
          <Image
            src={handAndCellphone}
            className={styles.imageCellphone}
            alt={handAndCellphoneDescription}
          />
        </div>
      </Fade>
    </div>
  )
}

HeroSectionCresol.propTypes = {
  logoCresol: PropTypes.string.isRequired,
  logoCresolDescription: PropTypes.string.isRequired,
  textOneSectionOneCresol: PropTypes.string.isRequired,
  textTwoSectionOneCresol: PropTypes.string.isRequired,
  handAndCellphone: PropTypes.string.isRequired,
  handAndCellphoneDescription: PropTypes.string.isRequired,
  backgroundSectionOneCresol: PropTypes.string.isRequired,
}

export default HeroSectionCresol
