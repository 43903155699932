import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import {
  EchoDesktop,
  EchoMainImages,
  EchoChallengeImages,
  EchoDiscoverImages,
  EchoDiscover2Images,
  EchoCallToActionImages,
} from '_molecules'

import styles from './styles.module.css'

const EchoDesktopContainer = ({
  currentSection,
  callToActionImages,
  lastSectionOffset,
  echoDesktopImage,
  echoDesktopImageAlt,
  heroImages,
  challengeImages,
  discoverImages,
}) => {
  const isLastSection = currentSection === 5
  const fadeSection = section => {
    return section === currentSection
  }

  return (
    <div
      className={styles.container}
      style={{
        minHeight: `${lastSectionOffset}px`,
      }}
    >
      <div
        className={classnames(
          styles.animaitonsContainer,
          isLastSection ? styles.animaitonsContainerAbsolute : styles.animaitonsContainerFixed
        )}
      >
        <div className={styles.animationsContent}>
          <EchoDesktop
            isLastSection={isLastSection}
            echoDesktopImage={echoDesktopImage}
            echoDesktopImageAlt={echoDesktopImageAlt}
          />
          <EchoMainImages shouldAnimate={fadeSection(1)} heroImages={heroImages} />
          <EchoChallengeImages shouldAnimate={fadeSection(2)} challengeImages={challengeImages} />
          <EchoDiscoverImages shouldAnimate={fadeSection(3)} discoverImages={discoverImages} />
          <EchoDiscover2Images shouldAnimate={fadeSection(4)} discoverImages={discoverImages} />
          <EchoCallToActionImages
            shouldAnimate={fadeSection(5)}
            callToActionImages={callToActionImages}
          />
        </div>
      </div>
    </div>
  )
}

EchoDesktopContainer.propTypes = {
  echoDesktopImage: PropTypes.string.isRequired,
  echoDesktopImageAlt: PropTypes.string.isRequired,
  heroImages: PropTypes.shape({
    phone: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    logo: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    woman: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    cart: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
  }).isRequired,
  challengeImages: PropTypes.shape({
    backBallon: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    frontBallon: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
  }).isRequired,
  discoverImages: PropTypes.shape({
    dialog1: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog2: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog3: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog4: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog5: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog6: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    dialog7: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
  }).isRequired,

  callToActionImages: PropTypes.shape({
    controller: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    phone: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
  }).isRequired,

  currentSection: PropTypes.number.isRequired,
  lastSectionOffset: PropTypes.number.isRequired,
}
export default EchoDesktopContainer
