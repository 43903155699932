import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Quote, TinySlider } from '_molecules'
import { SliderNav } from '_atoms'

import styles from './styles.module.css'

const renderQuote = quote => (
  <Quote
    white
    large
    text={quote.text.internal.content}
    user={quote.user}
    userPhoto={quote.userImage.fixed}
    userJob={quote.jobDescription}
    center
    hideUserPhotoMobile
    commaFormat
    color="greenLight"
  />
)

const StripedQuote = ({ sliderOptions, quotes, hasNav, className, containerClass }) => (
  <div className={classNames(styles.quote, className)}>
    <div className={styles.quoteBg}>
      <div className={classNames(styles.quoteContainer, containerClass)}>
        {sliderOptions ? (
          <TinySlider id="quote" options={sliderOptions} className={styles.quoteSlider}>
            {quotes.map(quote => (
              <div key={`home-quote-${quote.id}`}>{renderQuote(quote)}</div>
            ))}
          </TinySlider>
        ) : (
          renderQuote(quotes)
        )}

        {hasNav && (
          <div id="quote-nav" className={styles.quoteIcons}>
            {quotes.map(({ id }) => (
              <SliderNav key={id} isWhite />
            ))}
          </div>
        )}
      </div>
    </div>
  </div>
)

StripedQuote.propTypes = {
  sliderOptions: PropTypes.shape({}),
  quotes: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})])
    .isRequired,
  hasNav: PropTypes.bool,
  className: PropTypes.string,
  containerClass: PropTypes.string,
}

StripedQuote.defaultProps = {
  sliderOptions: undefined,
  hasNav: false,
  className: undefined,
  containerClass: undefined,
}

export default StripedQuote
