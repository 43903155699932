import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Img from 'gatsby-image'

import styles from './styles.module.css'

const EchoMobileContainer = ({ lastSectionOffset, currentSection, echoDotMobileImage }) => {
  const isLastSection = currentSection === 5

  return (
    <div
      className={styles.mobileEchoContainer}
      style={{
        minHeight: `${lastSectionOffset}px`,
      }}
    >
      <div
        className={classnames(
          styles.mobileEcho,
          isLastSection ? styles.mobileEchoPositioned : styles.mobileEchoFixed
        )}
      >
        <Img
          fadeIn={false}
          fluid={echoDotMobileImage}
          imgStyle={{ objectFit: 'contain', objectPosition: 'center bottom' }}
        />
      </div>
    </div>
  )
}

EchoMobileContainer.propTypes = {
  echoDotMobileImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  lastSectionOffset: PropTypes.number.isRequired,
  currentSection: PropTypes.number.isRequired,
}
export default EchoMobileContainer
