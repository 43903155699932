import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import PrototypeIcon from '_images/svgs/prototypeIcon.svg'
import WaveIcon from '_images/svgs/waveIcon.svg'
import { Text } from '_atoms'

import styles from './styles.module.css'

const RaimanaResults = ({
  titleResults,
  firstDescription,
  secondDescription,
  thirdDescription,
}) => {
  return (
    <div className={styles.results}>
      <div className={styles.backgroundDiagonal}>
        <div className={styles.content}>
          <Fade delay={200} distance="10%" bottom>
            <div className={styles.containerTitle}>
              <Text className={styles.title}>
                {titleResults}
                <span className={styles.dot}>.</span>
              </Text>
            </div>
            <div className={styles.containerFirstResult}>
              <PrototypeIcon className={styles.secondIcon} />
              <Text className={styles.text}>
                {firstDescription} <b>{secondDescription}</b>
              </Text>
            </div>
          </Fade>
          <div className={styles.containerSecondResult}>
            <Fade delay={450} distance="20%" bottom>
              <WaveIcon className={styles.secondIcon} />
              <Text className={styles.text}>{thirdDescription}</Text>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  )
}
RaimanaResults.propTypes = {
  titleResults: PropTypes.string.isRequired,
  firstDescription: PropTypes.string.isRequired,
  secondDescription: PropTypes.string.isRequired,
  thirdDescription: PropTypes.string.isRequired,
}
export default RaimanaResults
