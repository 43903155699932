import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'

import { Container, Heading, Text, Image, BlueText } from '_atoms'

import styles from './styles.module.css'

const ServicesList = ({ list }) =>
  list.map(service => (
    <li key={service.title}>
      <Heading type="h3" className={styles.title}>
        {service.title}
      </Heading>
      <Text splitParagraphs size="16" className={styles.servicesListDescription}>
        {service.description}
      </Text>
    </li>
  ))

const AndroidDevelopmentServices = ({
  ctaButton,
  androidServiceList,
  androidServicesDescription,
  androidServicesTitle,
  androidServiceImage,
}) => {
  return (
    <section className={styles.servicesSection} id="services">
      <Container>
        <Row className={styles.servicesSectionHeader} justify="center" align="middle">
          <Heading type="h2" bold className={styles.servicesSectionTitle}>
            {androidServicesTitle}
            <BlueText light squared className={styles.servicesSectionBlueDot}>
              .
            </BlueText>
          </Heading>
          <Text size="16" className={styles.servicesSectionDescription}>
            {androidServicesDescription}
          </Text>
        </Row>

        <Row justify="start" align="top" gutter={40}>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Image src={androidServiceImage} className={styles.servicesImage} />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <ul className={styles.servicesSectionList}>
              <ServicesList list={androidServiceList} />
            </ul>
            <Row className={styles.button}>{ctaButton}</Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

AndroidDevelopmentServices.propTypes = {
  ctaButton: PropTypes.instanceOf(Object).isRequired,
  androidServiceList: PropTypes.arrayOf({
    description: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  androidServicesDescription: PropTypes.string.isRequired,
  androidServicesTitle: PropTypes.string.isRequired,
  androidServiceImage: PropTypes.string.isRequired,
}

export default AndroidDevelopmentServices
