import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles.module.css'

const SIZES = {
  56: styles.text56,
  48: styles.text48,
  32: styles.text32,
  28: styles.text28,
  24: styles.text24,
  22: styles.text22,
  20: styles.text20,
  18: styles.text18,
  16: styles.text16,
  14: styles.text14,
  12: styles.text12,
}

export const COLORS = {
  dark: styles.dark,
  default: styles.default,
  white: styles.white,
  success: styles.success,
  danger: styles.danger,
  primary: styles.primary,
  accent: styles.accent,
  findHeliYellow: styles.findHeliYellow,
  findHeliBluePrimary: styles.findHeliBluePrimary,
  vppGreenAccent: styles.vppGreenAccent,
  lockitronPrimary: styles.lockitronPrimary,
  lockitronSecondary: styles.lockitronSecondary,
  suPink: styles.suPink,
  suGray: styles.suGray,
  suOrange: styles.suOrange,
  camioPrimary: styles.camioPrimary,
  camioSecondary: styles.camioSecondary,
  woovitPrimary: styles.woovitPrimary,
  sodexoSecondary: styles.sodexoSecondary,
}

const ALIGNMENTS = {
  default: '',
  right: styles.alignLeft,
  center: styles.alignCenter,
}

const clean = paragraph => paragraph.replace(/</g, '&lt;').replace(/>/g, '&gt;')

const format = paragraph => {
  const cleanParagraph = clean(paragraph)
  const hasBold = cleanParagraph.includes('__')

  const formatted = hasBold
    ? cleanParagraph.replace(/__(.*?)__/g, '<strong>$1</strong>')
    : paragraph

  return formatted
}

const Text = ({ size, color, align, children, className, bold, splitParagraphs, ...props }) => {
  const textProps = {
    className: classNames(
      styles.text,
      SIZES[size],
      COLORS[color],
      ALIGNMENTS[align],
      { [styles.bold]: bold },
      className
    ),
    ...props,
  }

  if (splitParagraphs) {
    const paragraphs = children
      .split('\n')
      .filter(paragraph => paragraph)
      .map(paragraph => format(paragraph))

    return paragraphs.map((paragraph, index) => (
      /* eslint-disable-next-line react/no-array-index-key, react/no-danger */
      <p key={index} {...textProps} dangerouslySetInnerHTML={{ __html: paragraph }} />
    ))
  }

  return <p {...textProps}>{children}</p>
}

Text.propTypes = {
  size: PropTypes.oneOf(Object.keys(SIZES)),
  color: PropTypes.oneOf(Object.keys(COLORS)),
  align: PropTypes.oneOf(Object.keys(ALIGNMENTS)),
  children: PropTypes.node,
  className: PropTypes.string,
  bold: PropTypes.bool,
  splitParagraphs: PropTypes.bool,
}

Text.defaultProps = {
  size: '16',
  color: 'default',
  align: 'default',
  className: undefined,
  children: undefined,
  bold: false,
  splitParagraphs: false,
}

export default Text
