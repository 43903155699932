import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import glassdoor2x from '_images/glassdoor@2x.png'
import glassdoor3x from '_images/glassdoor@3x.png'
import glassdoor from '_images/glassdoor.png'
import { Image } from '_atoms'

import styles from './styles.module.css'

// eslint-disable-next-line react/prop-types
const StarComponent = ({ green }) => {
  return (
    <div className={classNames(styles.glassdoorStar, { [styles.green]: green })}>
      <div className={styles.starIcon} />
    </div>
  )
}

const GlassdoorStars = ({ stars }) => {
  const starsArray = [0, 1, 2, 3, 4]

  return (
    <div className={styles.starsContainer}>
      <Image
        src={glassdoor}
        className={styles.glassdoorLogo}
        srcSet={`${glassdoor2x} 2x, ${glassdoor3x} 3x`}
      />
      {starsArray.map((item, index) =>
        index + 1 <= stars ? <StarComponent key={item} green /> : <StarComponent key={item} />
      )}
    </div>
  )
}

GlassdoorStars.propTypes = {
  stars: PropTypes.number.isRequired,
}

export default GlassdoorStars
