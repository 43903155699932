import React from 'react'
import Fade from 'react-reveal/Fade'
import PropTypes from 'prop-types'

import { Container, Heading, Text } from '_atoms'
import { CarouselSlider } from '_molecules'

import styles from './styles.module.css'

const getSliderOptions = navContainer => {
  return {
    controls: false,
    mouseDrag: true,
    loop: false,
    items: 1.2,
    gap: 0,
    swipeAngle: false,
    navContainer,
    responsive: {
      480: {
        gutter: 40,
      },
      768: {
        items: 3,
        gutter: 0,
      },
      1280: {
        autoWidth: true,
        gutter: 0,
      },
    },
  }
}

const SwfastWhiteLabelContainer = ({ title, description, images }) => {
  return (
    <Container className={styles.container}>
      <div className={styles.whiteLabelContent}>
        <div className={styles.whiteLabelDescription}>
          <Fade distance="10%" bottom>
            <Heading type="h4" color="white" bold className={styles.heading}>
              {title}
              <span className={styles.dotPrimary}>.</span>
            </Heading>
          </Fade>

          <div className={styles.text}>
            <Fade delay={200} distance="10%" bottom>
              <Text color="dark" size="32" splitParagraphs className={styles.paragraph}>
                {description}
              </Text>
            </Fade>
          </div>
        </div>
        <div className={styles.sliderContainer}>
          <Fade distance="10%" delay={350} bottom>
            <CarouselSlider
              id="white-label"
              images={images}
              options={getSliderOptions('#white-label-nav')}
            />
          </Fade>
        </div>
      </div>
    </Container>
  )
}

SwfastWhiteLabelContainer.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default SwfastWhiteLabelContainer
