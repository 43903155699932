import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { scrollTo } from '_utils/scroll'
import Arrow from '_images/svgs/scroll-arrow.inline.svg'

import Text from '../text'

import styles from './styles.module.css'

const ScrollArrow = ({ text, textColor, className, isButton, scrollId }) => (
  <div className={classnames(styles.scrollContainer, className)}>
    {text && <Text color={textColor}>{text}</Text>}
    <div className={classnames(styles.scrollArrow, { [styles.withText]: text })}>
      {isButton ? (
        <button
          type="button"
          className={styles.scrollArrowButton}
          onClick={() => scrollTo(scrollId)}
        >
          <Arrow />
        </button>
      ) : (
        <Arrow />
      )}
    </div>
  </div>
)

ScrollArrow.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  textColor: PropTypes.string,
  isButton: PropTypes.bool,
  scrollId: PropTypes.string,
}

ScrollArrow.defaultProps = {
  className: '',
  text: '',
  textColor: 'white',
  isButton: false,
  scrollId: undefined,
}

export default ScrollArrow
