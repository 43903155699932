import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'

import { Container, Heading, Text, Image, ScrollArrow } from '_atoms'

import styles from './styles.module.css'

const AndroidDevelopmentPhases = ({
  developmentPhases,
  developmentPhasesTitle,
  developmentPhasesDescription,
}) => {
  return (
    <section className={styles.phasesSection}>
      <Container>
        <Row align="middle" justify="center" className={styles.phasesSectionTitle}>
          <Heading type="h2" bold className={styles.phasesSectionTitle}>
            {developmentPhasesTitle}
          </Heading>
          <Text size="16" className={styles.phasesSectionDescription}>
            {developmentPhasesDescription}
          </Text>
        </Row>

        <div className={styles.phasesSectionTable}>
          <Row className={styles.phasesSectionTableHeader} justify="space-between">
            {developmentPhases.map((phase, i) => (
              <Col
                span={12}
                key={phase.imageTitle}
                className={`${styles.phasesSectionTableHeader} `}
              >
                <div className={styles.containerTitle}>
                  <Text className={`${styles.phasesSectionTableHeaderText} `}>0{i + 1}</Text>
                  <Text className={`${styles.phasesSectionTableHeaderTitle} `}>
                    {phase.imageSubtitle.split(' ')[0]}
                  </Text>
                  <Text className={`${styles.phasesSectionTableHeaderSubtitle}`}>
                    {phase.imageSubtitle.split(' ')[1]}
                  </Text>
                  <Text className={`${styles.phasesSectionTableHeaderSubtitleSecond}`}>
                    {phase.imageSubtitle.split(' ')[2]}
                  </Text>{' '}
                </div>

                <ScrollArrow isButton scrollId="technologies" className={styles.arrow} />
                <div className={styles.phasesMobile}>
                  <Col xs={12} sm={12} md={6} lg={6} className={styles.phasesSectionTableImage}>
                    <Image src={developmentPhases[i].image.file.url} />
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <div className={styles.containerDescription}>
                      <Text size="22" splitParagraphs className={styles.phasesSectionTableContent}>
                        {developmentPhases[i].imageDescription.imageDescription}
                      </Text>
                      {developmentPhases[i].imageDescription2 && (
                        <Text
                          size="22"
                          splitParagraphs
                          className={styles.phasesSectionTableContent2}
                        >
                          {developmentPhases[i].imageDescription2.imageDescription2}
                        </Text>
                      )}
                    </div>
                  </Col>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </section>
  )
}

AndroidDevelopmentPhases.propTypes = {
  developmentPhasesTitle: PropTypes.string.isRequired,
  developmentPhasesDescription: PropTypes.string.isRequired,
  developmentPhases: PropTypes.arrayOf({
    image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    imageTitle: PropTypes.string,
    imageSubtitle: PropTypes.string,
    imageDescription: PropTypes.shape({
      imageDescription: PropTypes.string,
    }),
    imageDescription2: PropTypes.shape({
      imageDescription2: PropTypes.string,
    }),
  }).isRequired,
}

export default AndroidDevelopmentPhases
