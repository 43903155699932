import React from 'react'
import Fade from 'react-reveal/Fade'
import { Row, Col } from 'react-simple-flex-grid'
import PropTypes from 'prop-types'

import { Container, Heading, Text, BlueText, Image } from '_atoms'

import styles from './styles.module.css'

const SuccessStories = ({ title, successStories }) => (
  <section className={styles.successStories}>
    <Container>
      <Fade distance="20%" bottom>
        <Heading type="h4" color="default" bold className={styles.successStoriesTitle}>
          {title}
          <BlueText squared>.</BlueText>
        </Heading>
      </Fade>

      <Row className={styles.successStoriesStoryWrapper}>
        {successStories.map((story, index) => (
          <Col
            xs={12}
            sm={4}
            md={4}
            lg={4}
            key={story.image.file.url}
            className={styles.successStoriesStory}
          >
            <Fade distance="20%" delay={index * 200} bottom>
              <Image src={story.image.file.url} alt={story.image.description} />
              <Text size="22" color="dark" className={styles.successStoriesStoryText}>
                {story.description.internal.content}
              </Text>
            </Fade>
          </Col>
        ))}
      </Row>
    </Container>
  </section>
)

SuccessStories.propTypes = {
  title: PropTypes.string.isRequired,
  successStories: PropTypes.arrayOf(Object).isRequired,
}

export default SuccessStories
