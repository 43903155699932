import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'
import Fade from 'react-reveal/Fade'
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl'
import classNames from 'classnames'

import { Container, Text, Image } from '_atoms'
import numberIcon1 from '_images/pages/about/numbers-1.svg'
import numberIcon2 from '_images/pages/about/numbers-2.svg'
import numberIcon3 from '_images/pages/about/numbers-3.svg'

import styles from './styles.module.css'

const CompanyStats = ({ intl, className }) => (
  <Container className={classNames(styles.number, className)}>
    <Row>
      <Col xs={12} sm={12} md={4} lg={4}>
        <Fade distance="25%" bottom>
          <div className={styles.numberContainer}>
            <Text size="56" color="dark" className={styles.numberData}>
              2013{' '}
              <Image
                alt={intl.formatMessage({ id: 'about.numbers.founded' })}
                src={numberIcon1}
                className={styles.numberIcon}
                width="67"
                height="27"
              />
            </Text>
            <Text size="28" color="dark" className={styles.numberDescription}>
              <FormattedMessage id="about.numbers.founded" />
            </Text>
          </div>
        </Fade>
      </Col>
      <Col xs={12} sm={12} md={4} lg={4}>
        <Fade distance="25%" bottom>
          <div className={styles.numberContainer}>
            <Text size="56" color="dark" className={styles.numberData}>
              100+{' '}
              <Image
                alt={intl.formatMessage({ id: 'about.numbers.members' })}
                src={numberIcon2}
                className={styles.numberIcon}
                width="104"
                height="28"
              />
            </Text>
            <Text size="28" color="dark" className={styles.numberDescription}>
              <FormattedMessage id="about.numbers.members" />
            </Text>
          </div>
        </Fade>
      </Col>
      <Col xs={12} sm={12} md={4} lg={4}>
        <Fade distance="25%" bottom>
          <div className={styles.numberContainer}>
            <Text size="56" color="dark" className={styles.numberData}>
              200+{' '}
              <Image
                alt={intl.formatMessage({ id: 'about.numbers.squads' })}
                src={numberIcon3}
                className={styles.numberIcon}
                width="84"
                height="28"
              />
            </Text>
            <Text size="28" color="dark" className={styles.numberDescription}>
              <FormattedMessage id="about.numbers.squads" />
            </Text>
          </div>
        </Fade>
      </Col>
    </Row>
  </Container>
)

CompanyStats.propTypes = {
  intl: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string,
}

CompanyStats.defaultProps = {
  className: undefined,
}

export default injectIntl(CompanyStats)
