import React from 'react'
import Fade from 'react-reveal/Fade'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { Image } from '_atoms'

import styles from './styles.module.css'

const EchoDesktopChallengeImages = ({ shouldAnimate, challengeImages }) => {
  return (
    <>
      <Fade delay={150} opposite when={shouldAnimate} distance="20%" bottom>
        <div className={classnames(styles.challengeDialog, styles.challengeDialogBack)}>
          <Image src={challengeImages.backBallon.image} alt={challengeImages.backBallon.alt} />
          <div />
        </div>
      </Fade>
      <Fade delay={350} opposite when={shouldAnimate} distance="20%" bottom>
        <div className={classnames(styles.challengeDialog, styles.challengeDialogFront)}>
          <Image src={challengeImages.frontBallon.image} alt={challengeImages.frontBallon.alt} />
          <div />
        </div>
      </Fade>
    </>
  )
}

EchoDesktopChallengeImages.propTypes = {
  shouldAnimate: PropTypes.bool.isRequired,
  challengeImages: PropTypes.shape({
    backBallon: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    frontBallon: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
  }).isRequired,
}

export default EchoDesktopChallengeImages
