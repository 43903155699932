import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useWindowSize } from '_hooks'
import { Text } from '_atoms'

import styles from './styles.module.css'

const SIDES = ['top', 'right', 'bottom', 'left']

const ColorRibbon = ({
  side,
  colors,
  className,
  colorClass,
  bold,
  center,
  textSize,
  textColors,
  responsiveColumns,
}) => {
  const [width] = useWindowSize()
  const isColumnLayout = width < 992

  const colorWidth = isColumnLayout
    ? `${(100 / colors.length) * responsiveColumns}%`
    : `${100 / colors.length}%`

  return (
    <div
      className={classNames(
        styles.colorRibbon,
        styles[side],
        { [styles.center]: center, [styles.bold]: bold },
        className
      )}
    >
      {colors.map((color, index) => (
        <div
          className={classNames(styles.color, colorClass)}
          style={{
            backgroundColor: color,
            width: colorWidth,
          }}
          key={color}
        >
          <Text
            size={textSize}
            color={textColors[index]}
            bold={bold}
            className={classNames(styles.colorText, { [styles[center]]: center })}
          >
            {color}
          </Text>
        </div>
      ))}
    </div>
  )
}

ColorRibbon.propTypes = {
  side: PropTypes.oneOf(SIDES).isRequired,
  colors: PropTypes.arrayOf(String).isRequired,
  className: PropTypes.string,
  colorClass: PropTypes.string,
  bold: PropTypes.bool,
  center: PropTypes.bool,
  textSize: PropTypes.string,
  textColors: PropTypes.arrayOf(String).isRequired,
  responsiveColumns: PropTypes.number,
}

ColorRibbon.defaultProps = {
  className: undefined,
  colorClass: undefined,
  bold: false,
  center: false,
  textSize: '18',
  responsiveColumns: 1,
}

export default ColorRibbon
