import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'

import { Container, Heading, Text, Image, BlueText } from '_atoms'
import arrow from '_images/pages/services/brands-arrow.svg'
import { useWindowSize } from '_hooks'
import TinySlider from '_molecules/tiny-slider'

import styles from './styles.module.css'

const getSliderOptions = {
  controls: false,
  mouseDrag: true,
  loop: false,
  swipeAngle: false,
  autoWidth: true,
  lazyload: true,
}

const AndroidDevelopmentBrands = ({
  trustedBrands,
  trustedBrandsDescription,
  trustedBrandsTitle,
}) => {
  const intl = useIntl()
  const isEnLocale = intl.locale === 'en'
  const [screenWidth] = useWindowSize()
  const isMobileView = screenWidth < 768

  const [selectedBrand, setSelectedBrand] = useState(0)
  return (
    <div className={styles.brands}>
      <Container>
        <Row>
          <Heading type="h2" bold className={styles.brandsTitle}>
            {trustedBrandsTitle}
            <BlueText light squared className={styles.blueDot}>
              .
            </BlueText>
          </Heading>
          <Text size="24" className={styles.brandsDescription}>
            {trustedBrandsDescription}
          </Text>
        </Row>
        {isMobileView ? (
          <TinySlider id="slider_brands" options={getSliderOptions}>
            {trustedBrands.map(item => (
              <Col className={styles.brandsBrandCard}>
                <Col className={styles.brandsBrandDescription}>
                  <Col className={styles.brandsBrandDescriptionColumn}>
                    <Text size="28" color="white" bold className={styles.brandsTextTitle}>
                      {item.brandName}
                    </Text>
                    <Text size="16" color="white" className={styles.brandsText}>
                      <b>{item.brandName}</b>
                      {item.brandDescription.brandDescription}
                    </Text>

                    <Row align="middle" className={styles.brandsSeeMore}>
                      <Image src={arrow} />
                      <Text size="16" color="white" className={styles.brandsSeeMoreText}>
                        <FormattedMessage id="services.androidDevelopment.see" />{' '}
                        <a href={item.brandLink} className={styles.brandsSeeMoreLink}>
                          {item.brandName}{' '}
                          {isEnLocale && (
                            <FormattedMessage id="services.androidDevelopment.useCase" />
                          )}
                        </a>
                      </Text>
                    </Row>
                  </Col>

                  <Col xs={12} sm={12} md={6} lg={6} className={styles.brandsImage}>
                    <Image src={item.brandImage.file.url} />
                  </Col>
                </Col>
              </Col>
            ))}
          </TinySlider>
        ) : (
          <Row className={styles.brandsGrid} gutter={50} justify="space-between">
            <Col xs={12} sm={12} md={2} lg={2} className={styles.brandsLogoGrid}>
              {trustedBrands.map((brand, index) => (
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => setSelectedBrand(index)}
                  onKeyDown={() => setSelectedBrand(index)}
                  onMouseOver={() => setSelectedBrand(index)}
                  onFocus={() => setSelectedBrand(index)}
                  className={`${styles.brandsLogo} ${
                    selectedBrand === index && styles.brandsLogoActive
                  }`}
                >
                  <Image src={brand.brandLogo.file.url} />
                </div>
              ))}
            </Col>

            <Col xs={12} sm={12} md={10} lg={10} className={styles.brandsBrandDescription}>
              <Col xs={12} sm={12} md={6} lg={6} className={styles.brandsBrandDescriptionColumn}>
                <Text size="16" color="white" className={styles.brandsText}>
                  <b className={styles.brandsName}> {trustedBrands[selectedBrand].brandName}</b>
                  {trustedBrands[selectedBrand].brandDescription.brandDescription}
                </Text>

                <Row align="middle" className={styles.brandsSeeMore}>
                  <Image src={arrow} />
                  <Text size="16" color="white" className={styles.brandsSeeMoreText}>
                    <FormattedMessage id="services.androidDevelopment.see" />{' '}
                    <a
                      href={trustedBrands[selectedBrand].brandLink}
                      className={styles.brandsSeeMoreLink}
                    >
                      {trustedBrands[selectedBrand].brandName}{' '}
                      {isEnLocale && <FormattedMessage id="services.androidDevelopment.useCase" />}
                    </a>
                  </Text>
                </Row>
              </Col>

              <Col xs={12} sm={12} md={6} lg={6} className={styles.brandsImage}>
                <Image src={trustedBrands[selectedBrand].brandImage.file.url} />
              </Col>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  )
}

AndroidDevelopmentBrands.propTypes = {
  trustedBrandsTitle: PropTypes.string.isRequired,
  trustedBrandsDescription: PropTypes.string.isRequired,
  trustedBrands: PropTypes.arrayOf({
    brandLogo: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    brandDescription: PropTypes.shape({
      brandDescription: PropTypes.string,
    }),
    brandName: PropTypes.string,
    brandImage: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    brandLink: PropTypes.string,
  }).isRequired,
}

export default AndroidDevelopmentBrands
