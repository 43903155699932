import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import { Image, Text, Container } from '_atoms'

import styles from './styles.module.css'

const QuiverQuantResults = ({
  sixthStripeText1,
  sixthStripeImg1,
  sixthStripeImg2,
  sixthStripe,
  sixthStripeText2,
  sixthStripeImg1Description,
  sixthStripeImg2Description,
}) => {
  return (
    <div className={styles.resultsBlock}>
      <div className={styles.overContainer}>
        <img className={styles.sixthStripe} src={sixthStripe} alt="diagonal stripe" />
      </div>
      <Container className={styles.resultsContainer}>
        <div className={styles.resultsItem}>
          <div className={styles.resultsText1}>
            <Text className={styles.resultsText1}>
              {sixthStripeText1}
              <Text size="48" className={styles.dot}>
                .
              </Text>
            </Text>
          </div>
        </div>
        <div className={styles.resultsItem}>
          <Fade delay={200} distance="10%" bottom>
            <Image
              src={sixthStripeImg1}
              alt={sixthStripeImg1Description}
              className={styles.sixthStripeImg1}
            />
          </Fade>
        </div>
        <div className={styles.resultsItem}>
          <Fade delay={200} distance="10%" bottom>
            <Image
              src={sixthStripeImg2}
              alt={sixthStripeImg2Description}
              className={styles.sixthStripeImg2}
            />
          </Fade>
        </div>
        <div className={styles.resultsItem}>
          <div className={styles.resultsText}>
            <Fade delay={200} distance="10%" bottom>
              <Text size="22" className={styles.resultsText}>
                {sixthStripeText2}
              </Text>
            </Fade>
          </div>
        </div>
      </Container>
    </div>
  )
}

QuiverQuantResults.propTypes = {
  sixthStripeText1: PropTypes.string.isRequired,
  sixthStripeImg1: PropTypes.string.isRequired,
  sixthStripeImg2: PropTypes.string.isRequired,
  sixthStripe: PropTypes.string.isRequired,
  sixthStripeText2: PropTypes.string.isRequired,
  sixthStripeImg1Description: PropTypes.string.isRequired,
  sixthStripeImg2Description: PropTypes.string.isRequired,
}

export default QuiverQuantResults
