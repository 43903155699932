import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const getDistance = elem => elem.getBoundingClientRect()

// top is equal zero when component is at the top of the page
// and decreases while scrolling to bottom
// navbar has a 93px height in its compact state
// bottom is equal zero when bottom of the component is at the top of the page
// bottom > 90 fixes setting more than one element as visible
const isInViewport = ({ top, bottom }) => top <= 90 && bottom > 90

// use this if you have elements with paddings that cannot be changed
// or elements whose top/bottom navbar color needs to change "sooner"
const isInCustomViewport = ({ top, bottom }, customViewport) =>
  top <= customViewport.top && bottom > customViewport.bottom

// you need to use this component inside a `position: relative` block
const ViewportObserver = React.memo(
  ({ scroll, onViewport, customViewport }) => {
    const [element, setElement] = useState(null)

    useEffect(() => {
      if (element) {
        const distance = getDistance(element)
        const isNormalViewport = !customViewport && isInViewport(distance)
        const isCustomViewport = customViewport && isInCustomViewport(distance, customViewport)

        if (isNormalViewport || isCustomViewport) onViewport(distance)
      }
    }, [element, onViewport, scroll, customViewport])

    return (
      <div
        ref={setElement}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -999,
        }}
      />
    )
  },
  (prevProps, props) => prevProps.scroll === props.scroll
)

ViewportObserver.propTypes = {
  scroll: PropTypes.number.isRequired,
  onViewport: PropTypes.func.isRequired,
  customViewport: PropTypes.shape({ top: PropTypes.number, bottom: PropTypes.number }),
}

ViewportObserver.defaultProps = {
  customViewport: undefined,
}

export default ViewportObserver
