import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'

import CurrencyIcon from '_images/svgs/skyroam-currency.inline.svg'
import { Container, Heading, Text } from '_atoms'

import styles from './styles.module.css'

const SkyroamCurrency = ({ title, description, image, alt }) => {
  return (
    <Container className={styles.currencyContainer}>
      <Row>
        <Col xs={12} sm={12} md={6} lg={6}>
          <div className={styles.currencyContent}>
            <Fade distance="10%" bottom>
              <Heading type="h4" color="white" bold className={styles.heading}>
                {title}
                <span className={styles.dotSecondary}>.</span>
              </Heading>
            </Fade>

            <div className={styles.text}>
              <Fade delay={200} distance="10%" bottom>
                <Text color="white" size="32" splitParagraphs className={styles.paragraph}>
                  {description}
                </Text>
              </Fade>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} className={styles.currencyImages}>
          <div className={styles.currencyBg}>
            <Fade delay={350} distance="20%" bottom>
              <CurrencyIcon />
            </Fade>
          </div>

          <div className={styles.currencyImage}>
            <Fade delay={450} distance="20%" bottom>
              <Img
                fluid={image}
                alt={alt}
                imgStyle={{
                  objectFit: 'contain',
                  position: 'relative',
                }}
                fadeIn={false}
              />
            </Fade>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

SkyroamCurrency.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  alt: PropTypes.string.isRequired,
}

export default SkyroamCurrency
