import React from 'react'
import Fade from 'react-reveal/Fade'
import PropTypes from 'prop-types'

import { SliderNav } from '_atoms'
import { TinySlider, Quote } from '_molecules'

import styles from './styles.module.css'

const TESTIMONIALS_SLIDER_OPTIONS = {
  controls: false,
  mouseDrag: true,
  loop: true,
  center: true,
  autoplay: true,
  autoplayHoverPause: true,
  autoplayTimeout: 8000,
  autoplayText: ['', ''],
  navContainer: '#testimonials-nav',
}
const Testimonials = ({ testimonials }) => {
  return (
    <div className={styles.testimonialsContainer}>
      <Fade distance="10%" bottom>
        <TinySlider id="testimonials" options={TESTIMONIALS_SLIDER_OPTIONS}>
          {testimonials.map(quote => (
            <div key={`home-quote-${quote.id}`}>
              <Quote
                mobileComplete
                color="skyroamSecondary"
                text={quote.text.internal.content}
                user={quote.user}
                userPhoto={quote.userImage.fixed}
                userJob={quote.jobDescription}
                imageClassName={styles.testimonialsImage}
              />
            </div>
          ))}
        </TinySlider>
        <div id="testimonials-nav" className={styles.testimonialsIcons}>
          {testimonials.map(({ id }) => (
            <SliderNav key={id} className={styles.testimonialsNav} />
          ))}
        </div>
      </Fade>
    </div>
  )
}

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Testimonials
