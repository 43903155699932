import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import { Image, Text, Container } from '_atoms'

import styles from './styles.module.css'

const QuiverQuantNotifications = ({
  fourthSectionCellphone2,
  fourthSectionText2,
  fourthSectionTitleText2,
  fourthSectionCellphone2Description,
}) => {
  return (
    <div className={styles.outContainer}>
      <Container>
        <div className={styles.innerContainer}>
          <Fade delay={200} distance="10%" bottom>
            <div className={styles.secondSection}>
              <div className={styles.secondLeftSide}>
                <Image
                  src={fourthSectionCellphone2}
                  alt={fourthSectionCellphone2Description}
                  className={styles.fourthSectionCellphone2}
                />
              </div>
              <div className={styles.secondRightSide}>
                <Text className={styles.fourthSectionTitle2}>
                  {fourthSectionTitleText2}
                  <span className={styles.dot}>.</span>
                </Text>
                <Text splitParagraphs className={styles.description}>
                  {fourthSectionText2}
                </Text>
              </div>
            </div>
          </Fade>
        </div>
      </Container>
    </div>
  )
}

QuiverQuantNotifications.propTypes = {
  fourthSectionCellphone2: PropTypes.string.isRequired,
  fourthSectionCellphone2Description: PropTypes.string.isRequired,
  fourthSectionText2: PropTypes.string.isRequired,
  fourthSectionTitleText2: PropTypes.string.isRequired,
}

export default QuiverQuantNotifications
