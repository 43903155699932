import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import Img from 'gatsby-image'

import styles from './styles.module.css'

const SkyroamImage = ({ image, alt }) => {
  return (
    <div className={styles.heroFixed}>
      <Fade delay={350} distance="20%" bottom>
        <Img
          fluid={image}
          alt={alt}
          objectFit="contain"
          imgStyle={{ objectFit: 'contain' }}
          fadeIn={false}
        />
      </Fade>
    </div>
  )
}

SkyroamImage.propTypes = {
  image: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  alt: PropTypes.string.isRequired,
}

export default SkyroamImage
