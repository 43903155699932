import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import BrandSymbol from '_images/svgs/brand-symbol.inline.svg'
import BrandType from '_images/svgs/brand-type.inline.svg'

import styles from './styles.module.css'

const COLORS = ['white', 'blue', 'dark', 'exmoxPrimary']

const Brand = ({ compact, color }) => {
  return (
    <div className={classNames(styles.brand, styles[color], { [styles.compact]: compact })}>
      <BrandSymbol className={styles.brandSymbol} />
      <div className={styles.brandTypeContainer}>
        <BrandType />
      </div>
    </div>
  )
}

Brand.propTypes = {
  compact: PropTypes.bool,
  color: PropTypes.oneOf(COLORS),
}

Brand.defaultProps = {
  compact: false,
  color: 'blue',
}

export default Brand
