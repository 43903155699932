import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import 'tiny-slider/dist/tiny-slider.css'
import styles from './styles.module.css'

let tinySlider = null

// I need to do this cause import this module in node will break the build
if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  const { tns } = require('tiny-slider/src/tiny-slider')
  tinySlider = tns
}

class TinySlider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentId: null,
      slider: null,
    }
  }

  componentDidMount() {
    this.initSlider()
  }

  initSliderEvents() {
    const { slider } = this.state

    const handleClick = () => slider.goTo('next')

    const handleUpdate = currInfo => {
      const { index, slideItems } = currInfo
      const nextIndex = index + 1 < slideItems.length ? index + 1 : 0

      slideItems[nextIndex].addEventListener('click', handleClick)
      if (slideItems[index]) slideItems[index].removeEventListener('click', handleClick)
    }

    if (slider) {
      slider.events.on('dragStart', slider.pause)
      slider.events.on('touchstart', slider.pause)

      slider.events.on('dragEnd', slider.play)
      slider.events.on('touchEnd', slider.play)

      if (!('ontouchstart' in window)) {
        slider.events.on('indexChanged', currInfo => handleUpdate(currInfo))
        const info = slider.getInfo()
        handleUpdate(info)
      }
    }
  }

  initSlider() {
    const { id, children, options } = this.props
    const { currentId } = this.state

    if (id === currentId || !children.length) return

    this.setState({ currentId: id })

    if (typeof window !== 'undefined' && tinySlider) {
      this.setState(
        {
          slider: tinySlider({
            container: `#${id}`,
            ...options,
          }),
        },
        this.initSliderEvents
      )
    }
  }

  updateCurrentItem(index) {
    const { slider } = this.state

    if (slider) {
      slider.goTo(index)
    }
  }

  render() {
    const { id, className, children } = this.props

    return (
      <div className={classNames(styles.tinySlider, className)}>
        <div id={id}>{children}</div>
      </div>
    )
  }
}

TinySlider.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

TinySlider.defaultProps = {
  className: undefined,
}

export default TinySlider
