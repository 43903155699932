import React from 'react'
import Fade from 'react-reveal/Fade'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { Image } from '_atoms'

import styles from './styles.module.css'

const EchoDesktop = ({ isLastSection, echoDesktopImage, echoDesktopImageAlt }) => {
  return (
    <Fade delay={150} distance="20%" bottom>
      <div
        className={classnames(
          styles.desktopEchoContainer,
          isLastSection ? styles.desktopEchoContainerFixed : styles.desktopEchoContainerAbsolute
        )}
      >
        <Image src={echoDesktopImage} alt={echoDesktopImageAlt} />
      </div>
    </Fade>
  )
}

EchoDesktop.propTypes = {
  isLastSection: PropTypes.bool.isRequired,
  echoDesktopImage: PropTypes.string.isRequired,
  echoDesktopImageAlt: PropTypes.string.isRequired,
}

export default EchoDesktop
