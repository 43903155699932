import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import variables from '_config/css-variables'
import { Container, SliderNav } from '_atoms'
import { TinySlider, Quote } from '_molecules'

import styles from './styles.module.css'

const QuiverQuantTestmonials = ({ testimonials }) => {
  return (
    <Container className={styles.testimonialsContainer}>
      <Fade bottom>
        <TinySlider id="testimonials">
          {testimonials.map(quote => (
            <div key={`home-quote-${quote.id}`}>
              <Quote
                mobileComplete
                color={variables['quiver-quote-color']}
                text={quote.text.internal.content}
                user={quote.user}
                userPhoto={quote.userImage.fixed}
                userJob={quote.jobDescription}
                imageClassName={styles.testimonialsImage}
              />
            </div>
          ))}
        </TinySlider>
        <div id="testimonials-nav" className={styles.testimonialsIcons}>
          {testimonials.map(({ id }) => (
            <SliderNav key={id} className={styles.testimonialsNav} />
          ))}
        </div>
      </Fade>
    </Container>
  )
}

QuiverQuantTestmonials.propTypes = {
  testimonials: PropTypes.arrayOf(Object).isRequired,
}

export default QuiverQuantTestmonials
