import React from 'react'
import { Row } from 'react-simple-flex-grid'
import Fade from 'react-reveal/Fade'
import PropTypes from 'prop-types'

import { Container, Text, Heading } from '_atoms'

import styles from './styles.module.css'

const SectionChallenge = ({ challengeTitle, challengeDescription }) => {
  return (
    <Container className={styles.container}>
      <Row>
        <Fade distance="10%" delay={200} bottom>
          <Heading type="h4" bold className={styles.challengeTitle}>
            {challengeTitle}
            <Text className={styles.dot}>.</Text>
          </Heading>
        </Fade>
        <Fade distance="10%" delay={300} bottom>
          <Text size="22" className={styles.challengeDescription} splitParagraphs>
            {challengeDescription}
          </Text>
        </Fade>
      </Row>
    </Container>
  )
}

SectionChallenge.propTypes = {
  challengeTitle: PropTypes.string.isRequired,
  challengeDescription: PropTypes.string.isRequired,
}
export default SectionChallenge
