import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import { FormattedMessage } from 'gatsby-plugin-intl'

import FacialIcon from '_images/svgs/facial_recognition.svg'
import ResulstIcon from '_images/svgs/Results.svg'
import LaunchIcon from '_images/svgs/launch-icon.svg'
import { Container, Text } from '_atoms'

import styles from './styles.module.css'

const ThawResults = ({ facial, seriesC, info }) => {
  return (
    <div className={styles.resultsBlock}>
      <Container className={styles.resultsContainer}>
        <div className={styles.resultsItem}>
          <Fade delay={350} distance="20%" bottom>
            <LaunchIcon className={styles.Launch} />
          </Fade>
          <div className={styles.resultsText}>
            <Fade distance="10%" bottom>
              <Text size="22" className={styles.resultsText}>
                <b>
                  <FormattedMessage id="thaw.results3" />{' '}
                </b>
                {seriesC.internal.content}
              </Text>
            </Fade>
          </div>
        </div>
        <div className={styles.resultsItem}>
          <Fade delay={350} distance="20%" bottom>
            <FacialIcon className={styles.resultsPayment} />
          </Fade>
          <div className={styles.resultsText}>
            <Fade delay={200} distance="10%" bottom>
              <Text size="22" className={styles.resultsText}>
                {facial.internal.content}
                <b>
                  {' '}
                  <FormattedMessage id="thaw.results2" />
                </b>
              </Text>
            </Fade>
          </div>
        </div>
        <div className={styles.resultsItem}>
          <Fade delay={350} distance="20%" bottom>
            <ResulstIcon className={styles.resultsIcon} />
          </Fade>
          <div className={styles.resultsText}>
            <Fade delay={200} distance="10%" bottom>
              <Text size="22" className={styles.resultsText}>
                {info.internal.content}{' '}
                <b>
                  <FormattedMessage id="thaw.results1" />
                </b>
              </Text>
            </Fade>
          </div>
        </div>
      </Container>
    </div>
  )
}

ThawResults.propTypes = {
  facial: PropTypes.shape({
    internal: PropTypes.shape({
      content: PropTypes.string.isRequired,
    }),
  }).isRequired,
  seriesC: PropTypes.shape({
    internal: PropTypes.shape({
      content: PropTypes.string.isRequired,
    }),
  }).isRequired,
  info: PropTypes.shape({
    internal: PropTypes.shape({
      content: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default ThawResults
