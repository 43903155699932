import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Text, Image } from '_atoms'

import styles from './styles.module.css'

const BenefitCard = ({ name, color, icon, className }) => {
  const cardRef = useRef()
  return (
    <div className={classnames(styles.benefitContainer, styles[color], className)} ref={cardRef}>
      <Image src={icon.file.url} alt={icon.description} />
      <Text>{name}</Text>
    </div>
  )
}

BenefitCard.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
}

BenefitCard.defaultProps = {
  name: undefined,
  color: undefined,
  icon: undefined,
  className: undefined,
}

export default BenefitCard
