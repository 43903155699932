import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import { Row, Col } from 'react-simple-flex-grid'

import serverIcon from '_images/pages/portfolio/skyroam/server.png'
import { Container, Heading, Image, Text } from '_atoms'

import styles from './styles.module.css'

const SkyroamServer = ({ title, description }) => {
  return (
    <Container>
      <Row className={styles.serverRow}>
        <Col xs={12} sm={12} md={6} lg={6} className={styles.serverImages}>
          <div className={styles.serverImage}>
            <Fade delay={350} distance="20%" bottom>
              <Image src={serverIcon} alt="Server" />
            </Fade>
          </div>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <div className={styles.serverContent}>
            <Fade distance="10%" bottom>
              <Heading type="h4" color="skyroamSecondary" bold className={styles.heading}>
                {title}
                <span className={styles.dotPrimary}>.</span>
              </Heading>
            </Fade>

            <div className={styles.text}>
              <Fade delay={200} distance="10%" bottom>
                <Text size="32" splitParagraphs className={styles.paragraph}>
                  {description}
                </Text>
              </Fade>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

SkyroamServer.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default SkyroamServer
