import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import { Row, Col } from 'react-simple-flex-grid'
import Img from 'gatsby-image'
import classnames from 'classnames'

import { Container, ScrollArrow, Image } from '_atoms'

import TextJSON from '../../../text-json'

import styles from './styles.module.css'

const MeliHeroContainer = ({ heroImages, heroDescription }) => {
  return (
    <Container>
      <Row>
        <Col xs={12} sm={12} md={5} lg={5}>
          <div className={styles.heroContent}>
            <Fade distance="10%" bottom>
              <Img
                className={styles.heroLogo}
                fluid={heroImages.logo.image}
                alt={heroImages.logo.alt}
                imgStyle={{ objectFit: 'contain', objectPosition: 'center bottom' }}
              />
            </Fade>
            <div className={styles.heroText}>
              <Fade delay={200} distance="10%" bottom>
                <TextJSON color="dark" size="28" className={styles.heroParagraph}>
                  {heroDescription}
                </TextJSON>
              </Fade>
            </div>
            <Fade delay={350} distance="20%" bottom>
              <ScrollArrow className={styles.heroArrow} isButton scrollId="challenge" />
            </Fade>
          </div>
          <Row>
            <div className={styles.heroFixedMobile}>
              <Col span={4}>
                <Image src={heroImages.phone.image} alt={heroImages.phone.alt} />
              </Col>
              <Col span={4} style={{ alignSelf: 'center', zIndex: 3 }}>
                <Img
                  className={styles.heroFixedMobileWoman}
                  fluid={heroImages.woman.image}
                  alt={heroImages.woman.alt}
                  objectFit="contain"
                  imgStyle={{ objectFit: 'contain', objectPosition: 'bottom left' }}
                  fadeIn={false}
                />
              </Col>
              <Col span={4} style={{ alignSelf: 'flex-end', zIndex: 3 }}>
                <Image
                  className={classnames('svg', styles.heroFixedMobileSvgBlur)}
                  src={heroImages.cart.image}
                  alt={heroImages.cart.alt}
                />
              </Col>
            </div>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

MeliHeroContainer.propTypes = {
  heroDescription: PropTypes.shape({}).isRequired,
  heroImages: PropTypes.shape({
    phone: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    logo: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    woman: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    cart: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
  }).isRequired,
}

export default MeliHeroContainer
