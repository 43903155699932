import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import { Row, Col } from 'react-simple-flex-grid'
import classNames from 'classnames'
import Img from 'gatsby-image'
import { useIntl } from 'gatsby-plugin-intl'

import { Container, Heading, Text } from '_atoms'

import styles from './styles.module.css'

const SwfastResults = ({
  storeImage,
  deadlineAndBudgetImage,
  resultsStoreDescriptionFirstPart,
  resultsStoreDescriptionSecondPart,
  resultsStoreDescriptionThirdPart,
  resultsDeadlineDescriptionFirstPart,
  resultsDeadlineDescriptionSecondPart,
}) => {
  const intl = useIntl()
  return (
    <div className={styles.resultsBg}>
      <Container className={styles.resultsContentWrapper}>
        <Row>
          <Col xs={12} sm={4} md={4} lg={4} className={styles.resultsColumnWrapper}>
            <div
              className={classNames(styles.resultsTextWrapper, styles.resultsHeadingTextWrapper)}
            >
              <Fade distance="10%" delay={200} bottom>
                <Heading type="h4" color="white" bold>
                  {intl.formatMessage({ id: 'casesCommon.results' })}
                  <span className={styles.dotPrimary}>.</span>
                </Heading>
              </Fade>
            </div>
          </Col>
          <Col xs={12} sm={4} md={4} lg={4} className={styles.resultsColumnWrapper}>
            <Fade distance="10%" delay={400} bottom>
              <div className={styles.resultsContainer}>
                <Img
                  fluid={storeImage}
                  imgStyle={{ objectFit: 'contain', objectPosition: 'center' }}
                />
                <div>
                  <Text color="white">{resultsStoreDescriptionFirstPart}</Text>
                  <Text color="vppGreenAccent">{resultsStoreDescriptionSecondPart}</Text>
                  <Text color="white">{resultsStoreDescriptionThirdPart}</Text>
                </div>
              </div>
            </Fade>
          </Col>
          <Col xs={12} sm={4} md={4} lg={4} className={styles.resultsColumnWrapper}>
            <Fade distance="10%" delay={600} bottom>
              <div className={styles.resultsContainer}>
                <Img
                  fluid={deadlineAndBudgetImage}
                  imgStyle={{ objectFit: 'contain', objectPosition: 'center' }}
                  className={styles.resultImage}
                />
                <div>
                  <Text color="white">{resultsDeadlineDescriptionFirstPart}</Text>
                  <Text color="vppGreenAccent">{resultsDeadlineDescriptionSecondPart}</Text>
                </div>
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

SwfastResults.propTypes = {
  storeImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  deadlineAndBudgetImage: PropTypes.shape({
    aspectRatio: PropTypes.number,
    sizes: PropTypes.string,
    src: PropTypes.string,
    srcSet: PropTypes.string,
    srcSetWebp: PropTypes.string,
    srcWebp: PropTypes.string,
  }).isRequired,
  resultsStoreDescriptionFirstPart: PropTypes.string.isRequired,
  resultsStoreDescriptionSecondPart: PropTypes.string.isRequired,
  resultsStoreDescriptionThirdPart: PropTypes.string.isRequired,
  resultsDeadlineDescriptionFirstPart: PropTypes.string.isRequired,
  resultsDeadlineDescriptionSecondPart: PropTypes.string.isRequired,
}

export default SwfastResults
