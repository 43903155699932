import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FormattedMessage, Link } from 'gatsby-plugin-intl'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

import { Arrow, Container, Text } from '_atoms'

import styles from './styles.module.css'

const CaseStudies = ({ isPurple, className, linkId }) => {
  const data = useStaticQuery(graphql`
    {
      blue: file(relativePath: { eq: "pages/about/hand.inline.png" }) {
        childImageSharp {
          fluid(maxWidth: 552) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      purple: file(relativePath: { eq: "pages/about/hand.inline.png" }) {
        childImageSharp {
          fluid(maxWidth: 552) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <section>
      <Container className={classNames(styles.ctaContainer, className)}>
        <Link to="/portfolio" id={linkId}>
          <div className={styles.ctaContent}>
            <div className={styles.ctaText}>
              <Text size="56" color="dark">
                <FormattedMessage id="caseStudies.text" />
              </Text>
              <Text size="56" color="dark" bold>
                <FormattedMessage id="caseStudies.textBold" />
              </Text>
            </div>
            <div className={styles.ctaTextMobile}>
              <Text size="56" color="dark" className={styles.text}>
                <FormattedMessage id="caseStudies.textMobile" />{' '}
                <span>
                  <FormattedMessage id="caseStudies.textBold" />
                </span>
              </Text>
            </div>
            <Arrow className={styles.ctaArrow} />
          </div>
        </Link>

        <div>
          <Img
            fluid={isPurple ? data.purple.childImageSharp.fluid : data.blue.childImageSharp.fluid}
            imgStyle={{ objectFit: 'contain' }}
            alt="Case studies"
            className={styles.ctaImage}
          />
        </div>
      </Container>
    </section>
  )
}

CaseStudies.propTypes = {
  isPurple: PropTypes.bool,
  className: PropTypes.string,
  linkId: PropTypes.string,
}

CaseStudies.defaultProps = {
  isPurple: false,
  className: undefined,
  linkId: undefined,
}

export default CaseStudies
