import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'
import Fade from 'react-reveal/Fade'

import SwfastLogo from '_images/svgs/swfast-logo.inline.svg'
import { Container, Text, ScrollArrow } from '_atoms'

import styles from './styles.module.css'

const SwfastContainer = ({ content }) => {
  return (
    <Container>
      <Row>
        <Col xs={12} sm={12} md={7} lg={7}>
          <div className={styles.heroContent}>
            <Fade distance="10%" bottom>
              <SwfastLogo className={styles.heroLogo} />
            </Fade>

            <div className={styles.heroText}>
              <Fade delay={200} distance="10%" bottom>
                <Text color="white" size="32" splitParagraphs className={styles.heroParagraph}>
                  {content}
                </Text>
              </Fade>
            </div>

            <ScrollArrow className={styles.heroArrow} isButton scrollId="challenge" />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

SwfastContainer.propTypes = {
  content: PropTypes.string.isRequired,
}

export default SwfastContainer
