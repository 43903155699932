import React from 'react'
import PropTypes from 'prop-types'
import { useIntl, FormattedMessage, Link } from 'gatsby-plugin-intl'
import marked from 'marked'
import classNames from 'classnames'

import { CASES_URL } from '_utils/config'
import { BlueText, Container, Heading, Image, Text } from '_atoms'
import ctaImage from '_images/cases-cta.png'

import styles from './styles.module.css'

const ProudCases = ({
  currentProject,
  caseStudies,
  showCTA,
  extendedCTA,
  mainCasesOnly,
  hasItemHover,
  hasCaseLink,
}) => {
  const intl = useIntl()

  const CONTACT_CTA = {
    id: 'yourProjectMayBeHere',
    main: false,
    platforms: {
      internal: {
        content: intl.formatMessage({ id: 'caseStudies.contact' }),
      },
    },
    projectColor: '#3660BB',
    projectName: intl.formatMessage({ id: 'caseStudies.cta' }),
    thumbnail: {
      description: 'Contact us thumbnail',
      fixed: {
        src: ctaImage,
        srcSet: '',
      },
      file: {
        details: {
          image: {
            width: 302,
            height: 434,
          },
        },
      },
    },
    title: intl.formatMessage({ id: 'caseStudies.cta' }),
  }

  const cases = caseStudies.filter(({ projectName, main }) => {
    const removeCurrent = (projectName || '').toLowerCase() !== (currentProject || '').toLowerCase()

    return mainCasesOnly ? removeCurrent && !main : removeCurrent
  })

  const allCases = showCTA ? [...cases, CONTACT_CTA] : cases

  const renderCaseItem = (id, projectColor, projectName, platforms, thumbnail) => (
    <div
      key={`${id}-item`}
      style={{ backgroundColor: projectColor }}
      className={classNames(styles.allCasesItem, {
        [styles.allCasesItemHover]: hasItemHover,
      })}
    >
      <div className={styles.allCasesContent} key={`${id}-content`}>
        <Text color="white" size="24" className={styles.allCasesName}>
          {projectName}
        </Text>
        <div
          className={styles.allCasesPlatform}
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={{
            __html: platforms && marked(platforms.internal.content),
          }}
        />
      </div>
      <Image
        {...(thumbnail.fixed || thumbnail.fluid)}
        alt={thumbnail.description}
        className={styles.allCasesThumbnail}
        width={thumbnail.file.details.image.width || '150'}
        height={thumbnail.file.details.image.height || '110'}
      />
    </div>
  )

  return (
    <section
      className={classNames(styles.allCases, {
        [styles.allCasesExtendedCTA]: extendedCTA,
      })}
    >
      <Container>
        <Heading type="h2" bold className={styles.heading}>
          <FormattedMessage id="portfolio.secondaryCaseStudies" />
          <BlueText squared>.</BlueText>
        </Heading>

        <div className={styles.allCasesGrid}>
          {allCases.map(({ id, projectColor, projectName, platforms, thumbnail }) =>
            hasCaseLink && CASES_URL[projectName] ? (
              <Link to={CASES_URL[projectName]} key={id} id={id}>
                {renderCaseItem(id, projectColor, projectName, platforms, thumbnail)}
              </Link>
            ) : (
              renderCaseItem(id, projectColor, projectName, platforms, thumbnail)
            )
          )}
        </div>
      </Container>
    </section>
  )
}

ProudCases.propTypes = {
  currentProject: PropTypes.string,
  caseStudies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showCTA: PropTypes.bool,
  extendedCTA: PropTypes.bool,
  mainCasesOnly: PropTypes.bool,
  hasItemHover: PropTypes.bool,
  hasCaseLink: PropTypes.bool,
}

ProudCases.defaultProps = {
  currentProject: undefined,
  showCTA: true,
  extendedCTA: false,
  mainCasesOnly: false,
  hasItemHover: true,
  hasCaseLink: true,
}

export default ProudCases
