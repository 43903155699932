import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import { Container, Text, Heading } from '_atoms'

import style from './styles.module.css'

const BluebenxTokens = ({ tokensTitle, tokensDescription, stellarBackground }) => {
  return (
    <Container className={style.tokensContainer}>
      <div className={style.tokensBg} style={{ backgroundImage: `url(${stellarBackground})` }}>
        <div className={style.tokensContent}>
          <Fade distance="10%" bottom>
            <Heading type="h2" className={style.tokensTitle}>
              {tokensTitle}
              <span className={style.dotSecondary}>.</span>
            </Heading>
            <Text size="22" splitParagraphs className={style.tokensDescription}>
              {tokensDescription}
            </Text>
          </Fade>
        </div>
      </div>
    </Container>
  )
}

BluebenxTokens.propTypes = {
  tokensTitle: PropTypes.string.isRequired,
  tokensDescription: PropTypes.string.isRequired,
  stellarBackground: PropTypes.string.isRequired,
}

export default BluebenxTokens
