import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import { Container, Text, Image, Heading } from '_atoms'

import styles from './styles.module.css'

const MissionSection = ({ missionIcon, missionIconAlt, missionTitle, missionText }) => {
  return (
    <Container>
      <div className={styles.content}>
        <div className={styles.contentLeft}>
          <Fade distance="20%" delay={200} bottom>
            <Image src={missionIcon} alt={missionIconAlt} />
          </Fade>
        </div>
        <div className={styles.contentRight}>
          <Fade distance="20%" delay={200} bottom>
            <Heading type="h1" bold className={styles.missionTitle}>
              {missionTitle}
              <Text className={styles.dot}>.</Text>
            </Heading>
            <Text size="22" className={styles.missionDescription} splitParagraphs>
              {missionText}
            </Text>
          </Fade>
        </div>
      </div>
    </Container>
  )
}

MissionSection.propTypes = {
  missionIcon: PropTypes.string.isRequired,
  missionIconAlt: PropTypes.string.isRequired,
  missionTitle: PropTypes.string.isRequired,
  missionText: PropTypes.string.isRequired,
}
export default MissionSection
