import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import { Image, Text } from '_atoms'

import styles from './styles.module.css'

const LysResults = ({
  resultsTitle,
  resultsFirstLogo,
  resultsFirstLogoAlt,
  resultsSecondLogo,
  resultsSecondLogoAlt,
  resultsTextFirst,
  resultsTextSecond,
  resultsTextThird,
  resultsTextFourth,
  resultsTextFifth,
  resultsTextSixth,
}) => {
  return (
    <div className={styles.results}>
      <div className={styles.backgroundDiagonal}>
        <div className={styles.content}>
          <Fade delay={200} distance="10%" bottom>
            <div className={styles.containerTitle}>
              <Text className={styles.title}>
                {resultsTitle}
                <span className={styles.dot}>.</span>
              </Text>
            </div>
            <div className={styles.containerFirstResult}>
              <Image
                src={resultsFirstLogo}
                alt={resultsFirstLogoAlt}
                className={styles.firstLogo}
              />
              <Text className={styles.text}>
                {resultsTextFirst} <strong>{resultsTextSecond}</strong>
                {resultsTextThird} <strong>{resultsTextFourth}</strong>
              </Text>
            </div>
          </Fade>
          <div className={styles.containerSecondResult}>
            <Fade delay={450} distance="20%" bottom>
              <Image
                src={resultsSecondLogo}
                alt={resultsSecondLogoAlt}
                className={styles.secondLogo}
              />
              <Text className={styles.text}>
                {resultsTextFifth} <strong>{resultsTextSixth}</strong>
              </Text>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  )
}
LysResults.propTypes = {
  resultsTitle: PropTypes.string.isRequired,
  resultsFirstLogo: PropTypes.string.isRequired,
  resultsFirstLogoAlt: PropTypes.string.isRequired,
  resultsSecondLogo: PropTypes.string.isRequired,
  resultsSecondLogoAlt: PropTypes.string.isRequired,
  resultsTextFirst: PropTypes.string.isRequired,
  resultsTextSecond: PropTypes.string.isRequired,
  resultsTextThird: PropTypes.string.isRequired,
  resultsTextFourth: PropTypes.string.isRequired,
  resultsTextFifth: PropTypes.string.isRequired,
  resultsTextSixth: PropTypes.string.isRequired,
}
export default LysResults
