import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import { Image, Text } from '_atoms'

import styles from './styles.module.css'

const LysStudent = ({
  studentIllustration,
  studentIllustrationAlt,
  studentIllustrationMobile,
  studentIllustrationMobileAlt,
  studentTitle,
  studentDescription,
}) => {
  const [windowWidth, setWindowWidth] = useState()

  useEffect(() => {
    setWindowWidth(window.innerWidth)
  }, [windowWidth])

  const isMobile = windowWidth < 500

  return (
    <div className={styles.backgroundDiagonal}>
      <div className={styles.container}>
        <div className={styles.containerIllustration}>
          <Fade delay={200} distance="10%" bottom>
            {isMobile ? (
              <Image
                src={studentIllustrationMobile}
                alt={studentIllustrationMobileAlt}
                className={styles.studentIllustration}
              />
            ) : (
              <Image
                src={studentIllustration}
                alt={studentIllustrationAlt}
                className={styles.studentIllustration}
              />
            )}
          </Fade>
        </div>
        <div className={styles.containerDescription}>
          <Fade delay={450} distance="20%" bottom>
            <Text className={styles.title}>
              {studentTitle}
              <span className={styles.dot}>.</span>
            </Text>
            <Text className={styles.description}>{studentDescription}</Text>
          </Fade>
        </div>
      </div>
    </div>
  )
}

LysStudent.propTypes = {
  studentIllustration: PropTypes.string.isRequired,
  studentIllustrationAlt: PropTypes.string.isRequired,
  studentIllustrationMobile: PropTypes.string.isRequired,
  studentIllustrationMobileAlt: PropTypes.string.isRequired,
  studentTitle: PropTypes.string.isRequired,
  studentDescription: PropTypes.string.isRequired,
}
export default LysStudent
