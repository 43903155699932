import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import { useWindowSize } from '_hooks'
import { Container, Text, Image, Heading } from '_atoms'

import styles from './styles.module.css'

const ReportingSection = ({
  reportingTitle,
  reportingDescription,
  reportingImageDashboard,
  reportingImageDashboardAlt,
  reportingMobile,
  reportingMobileAlt,
}) => {
  const [width] = useWindowSize()
  const isMobile = width <= 768
  return (
    <Container>
      <div className={styles.content}>
        <Fade delay={200} distance="20%" bottom>
          <Heading type="h4" bold className={styles.reportingTitle}>
            {reportingTitle}
            <Text className={styles.dot}>.</Text>
          </Heading>
          <Text className={styles.reportingDescription} size="22" splitParagraphs>
            {reportingDescription}
          </Text>
        </Fade>
        <Fade delay={300} distance="20%" bottom>
          {isMobile ? (
            <Image
              src={reportingMobile}
              alt={reportingMobileAlt}
              className={styles.contentImageMobile}
            />
          ) : (
            <div className={styles.containerImg}>
              <Image
                src={reportingImageDashboard}
                alt={reportingImageDashboardAlt}
                className={styles.contentImage}
              />
            </div>
          )}
        </Fade>
      </div>
    </Container>
  )
}
ReportingSection.propTypes = {
  reportingTitle: PropTypes.string.isRequired,
  reportingDescription: PropTypes.string.isRequired,
  reportingImageDashboard: PropTypes.string.isRequired,
  reportingImageDashboardAlt: PropTypes.string.isRequired,
  reportingMobile: PropTypes.string.isRequired,
  reportingMobileAlt: PropTypes.string.isRequired,
}

export default ReportingSection
