import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import styles from './styles.module.css'

const Image = ({ className, src, alt, srcSet, radius, circular, eager, ...props }) => {
  const imageProps = {
    className: classNames(styles.image, { [styles.circular]: circular }, className),
    style: radius ? { borderRadius: radius } : {},
    src,
    srcSet: srcSet || undefined,
    loading: eager ? 'eager' : 'lazy',
    ...props,
  }

  return <img alt={alt} {...imageProps} />
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  radius: PropTypes.number,
  circular: PropTypes.bool,
  /**
   * @expected 'file/path/img@2x.png 2x, file/path/img@3x.png 3x'
   */
  srcSet: PropTypes.string,
  className: PropTypes.string,
  eager: PropTypes.bool,
}

Image.defaultProps = {
  srcSet: '',
  className: '',
  radius: 0,
  circular: false,
  eager: false,
}

export default Image
