import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import classNames from 'classnames'
import { FormattedMessage } from 'gatsby-plugin-intl'

import { Container, Heading, Text } from '_atoms'
import PaymentIcon from '_images/svgs/payment.inline.svg'
import CartIcon from '_images/svgs/cart.inline.svg'

import styles from './styles.module.css'

const SkyroamResults = ({ workingTogether, sales, seriesC, info }) => {
  return (
    <div className={styles.resultsBlock}>
      <Container className={styles.resultsContainer}>
        <div className={styles.resultsItem}>
          <Fade distance="10%" bottom>
            <Heading
              type="h4"
              bold
              color="white"
              className={classNames(styles.resultsHeading, styles.resultsHighlight)}
            >
              {workingTogether}{' '}
              <span className={styles.resultsYears}>
                <FormattedMessage id="casesCommon.years" />
              </span>
            </Heading>
          </Fade>
          <Fade delay={200} distance="10%" bottom>
            <Text color="white" size="18">
              <FormattedMessage id="casesCommon.workingTogether" />
            </Text>
          </Fade>
        </div>
        <div className={styles.resultsItem}>
          <Fade delay={350} distance="20%" bottom>
            <CartIcon className={styles.resultsCart} />
          </Fade>
          <div className={classNames(styles.resultsText, styles.resultsSales)}>
            <Fade distance="10%" bottom>
              <Heading type="h4" bold color="white" className={styles.resultsHeading}>
                {sales}
              </Heading>
            </Fade>
            <Fade delay={200} distance="10%" bottom>
              <Text color="white" size="18">
                <FormattedMessage id="skyroam.sales" />
              </Text>
            </Fade>
          </div>
        </div>
        <div className={styles.resultsItem}>
          <Fade delay={350} distance="20%" bottom>
            <PaymentIcon className={styles.resultsPayment} />
          </Fade>
          <div className={styles.resultsText}>
            <Fade distance="10%" bottom>
              <Text color="white" size="18">
                <FormattedMessage id="skyroam.raised" />
              </Text>
            </Fade>
            <Fade delay={200} distance="10%" bottom>
              <Heading type="h4" bold color="white" className={styles.resultsSeriesC}>
                {seriesC}
              </Heading>
            </Fade>
            <Fade delay={400} distance="10%" bottom>
              <Text color="white" size="18">
                <FormattedMessage id="skyroam.seriesCRound" />
              </Text>
            </Fade>
          </div>
        </div>
        <div className={styles.resultsInfo}>
          <Fade distance="10%" bottom>
            <Heading type="h4" bold color="white" className={styles.resultsHeading}>
              <FormattedMessage id="casesCommon.results" />
              <span className={styles.dotSecondary}>.</span>
            </Heading>
          </Fade>
          <Fade delay={200} distance="10%" bottom>
            <Text color="white" className={styles.resultsInfoText}>
              {info}
            </Text>
          </Fade>
        </div>
      </Container>
    </div>
  )
}

SkyroamResults.propTypes = {
  workingTogether: PropTypes.string.isRequired,
  sales: PropTypes.string.isRequired,
  seriesC: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
}

export default SkyroamResults
