import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import classNames from 'classnames'

import styles from './styles.module.css'

const BrandsSVG = ({ className, name }) => {
  const {
    allFile: { edges: brandsSVG },
  } = useStaticQuery(graphql`
    {
      allFile(filter: { absolutePath: { regex: "/svgs/pages/home/brands/" } }) {
        edges {
          node {
            name
            publicURL
          }
        }
      }
    }
  `)

  const imageSrc = useMemo(
    () => brandsSVG.find(({ node }) => `${name}.svg` === `${node.name}.svg`),
    [brandsSVG, name]
  )

  return (
    <div className={classNames(styles.brandsSVG, className)}>
      <img src={imageSrc.node.publicURL} alt={name} loading="lazy" />
    </div>
  )
}

BrandsSVG.propTypes = {
  className: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default BrandsSVG
