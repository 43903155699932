import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'

import { Text } from '_atoms'

import styles from './styles.module.css'

const RaimanaMarketplace = ({ marketplaceTitle, marketplaceDescription, marketplaceImage }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.containerDescription}>
          <Fade delay={200} distance="10%" bottom>
            <Text className={styles.title}>
              {marketplaceTitle}
              <span className={styles.dot}>.</span>
            </Text>
            <Text splitParagraphs className={styles.description}>
              {marketplaceDescription}
            </Text>
          </Fade>
        </div>

        <div className={styles.containerImage}>
          <Fade delay={450} distance="20%" bottom>
            <Img fluid={marketplaceImage} fadeIn={false} />
          </Fade>
        </div>
      </div>
    </div>
  )
}

RaimanaMarketplace.propTypes = {
  marketplaceTitle: PropTypes.string.isRequired,
  marketplaceDescription: PropTypes.string.isRequired,
  marketplaceImage: PropTypes.shape({
    description: PropTypes.string,
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      sizes: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcSetWebp: PropTypes.string,
      srcWebp: PropTypes.string,
    }),
  }).isRequired,
}
export default RaimanaMarketplace
