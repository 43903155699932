import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import PropTypes from 'prop-types'
import { Link } from 'gatsby-plugin-intl'

import { Text } from '_atoms'

import styles from './styles.module.css'

const textOptions = textProps => ({
  renderMark: {
    bold: text => <strong>{text}</strong>,
  },
  renderNode: {
    paragraph: (node, children) => <Text {...textProps}>{children}</Text>,
    hyperlink: (node, children) => {
      const { data } = node
      if (data.uri.indexOf('http') >= 0) {
        return (
          <a className={styles.link} href={data.uri} rel="noopener noreferrer" target="_blank">
            {children}
          </a>
        )
      }
      return (
        <Link className={styles.link} to={data.uri}>
          {children}
        </Link>
      )
    },
  },
})

const TextJSON = ({ children, ...props }) => (
  <>{documentToReactComponents(children, textOptions(props))}</>
)

TextJSON.propTypes = {
  children: PropTypes.shape({}).isRequired,
}

export default TextJSON
