export const QUOTE_SLIDER_OPTIONS = {
  navContainer: '#testimonials-nav',
  controls: false,
  mouseDrag: true,
  loop: true,
  center: true,
  gutter: 64,
  autoplay: true,
  items: 1,
  autoplayHoverPause: false,
  autoplayTimeout: 8000,
  autoplayText: ['', ''],
  responsive: {
    768: {
      gutter: 100,
    },
    1280: {
      gutter: 150,
    },
    1440: {
      gutter: 500, // fix next slide visible at big screens (>=1920px)
    },
  },
}

export const CASES_URL = {
  'AES Brasil': '/portfolio/aes-vpp/',
  FindHeli: '/portfolio/findheli/',
  Tapcart: '/portfolio/tapcart/',
  Lockitron: '/portfolio/lockitron/',
  Skyroam: '/portfolio/skyroam/',
  'Singularity University': '/portfolio/singularity-university/',
  'Your Product May Be Here': '/contact/',
  'Seu Produto Pode Estar Aqui': '/contact/',
  Camio: '/portfolio/camio/',
  'EQI Investimentos': '/portfolio/eqi/',
  ExMox: '/portfolio/exmox/',
  Woovit: '/portfolio/woovit/',
  Swfast: '/portfolio/swfast/',
  MercadoLibre: '/portfolio/meli/',
  Sodexo: '/portfolio/sodexo/',
  'Quiver Quantitative': '/portfolio/quiverquantitative/',
  Thaw: '/portfolio/thaw/',
  Bluebenx: '/portfolio/bluebenx/',
  'Lys Academy': '/portfolio/lys/',
  Move: '/portfolio/move/',
  Cresol: '/portfolio/cresol/',
  Raimana: '/portfolio/raimana/',
}

// https://stackoverflow.com/a/2970667
export const camelCase = str => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
}

export const IMG_AS_HERO_CONTAINER_STYLES = {
  objectFit: 'cover',
  position: 'absolute',
  bottom: '0',
  left: '0',
  width: '100%',
  minHeight: '100%',
}

export const IMG_AS_HERO_IMG_STYLES = {
  objectFit: 'cover',
  objectPosition: 'center center',
}
