import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import { Image, Text, Container, Heading } from '_atoms'

import styles from './styles.module.css'

const QuiverQuantChallenge = ({
  secondSectionTitleText,
  secondSectionText1,
  secondSectionImgGroup,
  secondSectionImgGroupDescription,
}) => {
  return (
    <div className={styles.outContainer}>
      <Container>
        <div className={styles.innerContainer}>
          <Fade delay={200} distance="10%" bottom>
            <div className={styles.leftSide}>
              <Heading type="h1" color="white" className={styles.titleChallenge}>
                {secondSectionTitleText}
                <span className={styles.dot}>.</span>
              </Heading>
              <Text splitParagraphs className={styles.description}>
                {secondSectionText1}
              </Text>
            </div>
          </Fade>
          <Fade delay={450} distance="20%" bottom>
            <div className={styles.rightSide}>
              <Image
                src={secondSectionImgGroup}
                alt={secondSectionImgGroupDescription}
                className={styles.imgGroup}
              />
            </div>
          </Fade>
        </div>
      </Container>
    </div>
  )
}

QuiverQuantChallenge.propTypes = {
  secondSectionImgGroupDescription: PropTypes.string.isRequired,
  secondSectionTitleText: PropTypes.string.isRequired,
  secondSectionText1: PropTypes.string.isRequired,
  secondSectionImgGroup: PropTypes.string.isRequired,
}

export default QuiverQuantChallenge
