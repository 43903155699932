import React from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'
import classnames from 'classnames'

import { Heading, BlueText, Image } from '_atoms'
import EmptyImage from '_images/pages/careers/openings-empty.png'

import styles from './styles.module.css'

const Empty = () => (
  <div className={styles.emptyWrapper}>
    <Image src={EmptyImage} alt="Openings empty" />
    <Heading type="h2" className={classnames(styles.title, styles.emptyTitle)}>
      <FormattedMessage id="careers.openings.title" /> <BlueText squared>.</BlueText>
    </Heading>
    <Heading type="h3">
      <FormattedMessage id="careers.openings.emptySubtitle" />
    </Heading>
  </div>
)

export default Empty
