import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'
import Fade from 'react-reveal/Fade'

import { Image } from '_atoms'

import styles from './styles.module.css'

const EchoDesktopCallToActionImages = ({ shouldAnimate, callToActionImages }) => {
  return (
    <>
      <div className={styles.callToActionOverlapContainer}>
        <Row>
          <Col span={6}>
            <Fade delay={150} opposite when={shouldAnimate} distance="20%" bottom>
              <Image
                src={callToActionImages.controller.image}
                alt={callToActionImages.controller.alt}
              />
            </Fade>
          </Col>
          <Col span={6} />
        </Row>
        <Row style={{ paddingTop: '10%' }}>
          <Col span={6} />
          <Col span={6}>
            <Fade delay={550} opposite when={shouldAnimate} distance="20%" bottom>
              <Image src={callToActionImages.monitor.image} alt={callToActionImages.monitor.alt} />
            </Fade>
          </Col>
        </Row>
      </div>

      <div className={styles.callToActionUnderlapContainer}>
        <Row>
          <Col span={6} />
          <Col span={6}>
            <Fade delay={350} opposite when={shouldAnimate} distance="20%" bottom>
              <Image src={callToActionImages.phone.image} alt={callToActionImages.phone.alt} />
            </Fade>
          </Col>
        </Row>
        <Row style={{ marginTop: '-10%' }}>
          <Col span={6}>
            <Fade delay={750} opposite when={shouldAnimate} distance="20%" bottom>
              <Image src={callToActionImages.headset.image} alt={callToActionImages.headset.alt} />
            </Fade>
          </Col>
          <Col span={6} />
        </Row>
      </div>
    </>
  )
}

EchoDesktopCallToActionImages.propTypes = {
  shouldAnimate: PropTypes.bool.isRequired,
  callToActionImages: PropTypes.shape({
    controller: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    phone: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    monitor: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
    headset: {
      image: PropTypes.string,
      alt: PropTypes.string,
    },
  }).isRequired,
}

export default EchoDesktopCallToActionImages
