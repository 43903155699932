import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'

import styles from './styles.module.css'

const ServiceIcons = ({ name }) => {
  const {
    allFile: { edges: servicesIcons },
  } = useStaticQuery(graphql`
    {
      allFile(filter: { absolutePath: { regex: "/svgs/pages/home/service-icons/" } }) {
        edges {
          node {
            name
            publicURL
          }
        }
      }
    }
  `)

  const imageSrc = useMemo(
    () => servicesIcons.find(({ node }) => `${name}.svg` === `${node.name}.svg`),
    [servicesIcons, name]
  )

  return (
    <div className={styles.serviceIcons}>
      <img src={imageSrc.node.publicURL} alt={name} loading="lazy" />
    </div>
  )
}

ServiceIcons.propTypes = {
  name: PropTypes.string.isRequired,
}

export default ServiceIcons
