import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'

import { Container, Heading, Text, Image } from '_atoms'

import styles from './styles.module.css'

const AndroidDevelopmentTechnologies = ({
  developmentTechnologies,
  developmentTechnologiesTitle,
  developmentTechnologiesDescription,
}) => {
  return (
    <section id="technologies" className={styles.technologiesSection}>
      <Container>
        <Row align="center" justify="center">
          <Heading type="h2" bold className={styles.technologiesSectionTitle}>
            {developmentTechnologiesTitle}
          </Heading>
          <Text size="24" className={styles.technologiesSectionDescription}>
            {developmentTechnologiesDescription}
          </Text>
        </Row>

        <Row align="stretch" justify="center" className={styles.technologiesGrid}>
          {developmentTechnologies.map(technology => (
            <Col xs={5} sm={3} md={3} lg={3}>
              <Col key={technology.imageTitle} className={styles.technology}>
                <Image src={technology.image.file.url} />
                <Text size="16" className={styles.technologyTitle}>
                  {technology.title}
                </Text>
              </Col>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

AndroidDevelopmentTechnologies.propTypes = {
  developmentTechnologiesTitle: PropTypes.string.isRequired,
  developmentTechnologiesDescription: PropTypes.string.isRequired,
  developmentTechnologies: PropTypes.arrayOf({
    image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    title: PropTypes.string,
  }).isRequired,
}

export default AndroidDevelopmentTechnologies
