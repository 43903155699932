import React from 'react'
import Fade from 'react-reveal/Fade'
import { Row, Col } from 'react-simple-flex-grid'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import { Container, Heading, Text, BlueText } from '_atoms'

import styles from './styles.module.css'

const Awards = ({ title, awards }) => (
  <section className={styles.awards}>
    <Container>
      <Fade distance="20%" delay={200} bottom>
        <Heading type="h4" color="default" bold className={styles.awardsTitle}>
          {title}
          <BlueText squared>.</BlueText>
        </Heading>
      </Fade>

      <Row className={styles.awardsAwardWrapper}>
        {awards.map((award, index) => (
          <Col xs={12} sm={4} md={4} lg={4} key={award.title} className={styles.awardsAward}>
            <Fade distance="20%" delay={(index + 1) * 200} bottom>
              <Img
                fixed={award.image.fixed}
                alt={award.image.description}
                className={styles.awardsAwardImage}
              />
              <Text size="24" color="dark" className={styles.awardsAwardTitle}>
                {award.title}
              </Text>

              <Text size="16" color="default" className={styles.awardsAwardDescription}>
                {award.description}
              </Text>
            </Fade>
          </Col>
        ))}
      </Row>
    </Container>
  </section>
)

Awards.propTypes = {
  title: PropTypes.string.isRequired,
  awards: PropTypes.arrayOf(Object).isRequired,
}

export default Awards
